import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import CookiesBrick from '@src/components/bricks/CookiesBrick'
import React from 'react'

const CookiesBrickResolve = (brick) => {
	const margins = getMarginsFromBricks(brick)
	const data = {
		margins,
	}

	return <CookiesBrick {...data} key={brick.key} />
}

export default CookiesBrickResolve
