import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import AccordionBrick from '@src/components/bricks/AccordionBrick'
import React from 'react'

const accordionBrickResolve = (brick) => {
	const blockEditablesArray = findEditableByName(
		brick.editables,
		'contentblock',
	)
	var blockEditables = {}
	const arrayLength =
		blockEditablesArray && blockEditablesArray.objects
			? blockEditablesArray.objects.length
			: 0
	for (var i = 0; i < arrayLength; i++) {
		const editable = blockEditablesArray.objects[i]

		// Get the index of the editable in $editableIndex
		const editableIndexRegex = /contentblock:([0-9]+)/i
		const regexResult = editableIndexRegex.exec(editable.name)
		const editableIndex = parseInt(regexResult[1])

		const textRegex = /accordionText/gi
		const isText = textRegex.test(editable.name)

		if (blockEditables['accordion' + editableIndex] == null) {
			blockEditables['accordion' + editableIndex] = {}
		}

		if (isText) {
			blockEditables['accordion' + editableIndex] = Object.assign(
				{ text: editable.value },
				blockEditables['accordion' + editableIndex],
			)
		} else {
			blockEditables['accordion' + editableIndex] = Object.assign(
				{ title: editable.value },
				blockEditables['accordion' + editableIndex],
			)
		}
	}

	const margins = getMarginsFromBricks(brick)

	const data = {
		margins,
		brickTitle: findEditableByName(brick.editables, 'title').value,

		blockEditables,
	}

	return <AccordionBrick {...data} key={brick.key}></AccordionBrick>
}

export default accordionBrickResolve
