import './BrickContainer.scss'
import React from 'react'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('brick-container')

const BrickContainer = ({ data, children }) => {
	return (
		<div
			{...bem(
				'',
				data.margins.marginTop
					? 'margin-top'
					: data.margins.marginBottom
					? 'margin-bottom'
					: data.margins.marginTopBottom && 'margin-top-bottom',
			)}
		>
			{children}
		</div>
	)
}

export default BrickContainer
