import React from 'react'
import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'

import LeadBrick from '@src/components/bricks/LeadBrick'

export default function leadBrickResolve(brick) {
	const margins = getMarginsFromBricks(brick)

	const data = {
		margins,
		bigGrid: findEditableByName(brick.editables, 'big-grid').value,
		area: findEditableByName(brick.editables, 'area').value,
		titleTag: findEditableByName(brick.editables, 'titleTag').value,
		formUrl: findEditableByName(brick.editables, 'formUrl').value,
		title: findEditableByName(brick.editables, 'title').value,
		privacyText: findEditableByName(brick.editables, 'privacyText').value,
		buttonText: findEditableByName(brick.editables, 'buttonText').value,
	}

	return <LeadBrick {...data} key={brick.key} />
}
