import './WysiwygBrick.scss'

import React, { useRef, useEffect } from 'react'
import BEMHelper from 'react-bem-helper'
import { Container } from '@material-ui/core'
import { navigate } from 'gatsby'
import BrickContainer from '@src/components/bricks/BrickContainer'

const bem = new BEMHelper('wysiwyg-brick')

function handleNavigation(event) {
	navigate(event.target.pathname)
	event.stopPropagation()
	event.preventDefault()
}

const Wysiwyg = React.memo(({ content }) => {
	const wysiwygEl = useRef(null)
	useEffect(() => {
		const anchors = wysiwygEl.current.getElementsByTagName('a')

		for (let anchor of anchors) {
			if (anchor.host === window.location.host) {
				anchor.addEventListener('click', handleNavigation, true)
			}
		}

		return () => {
			for (let anchor of anchors) {
				if (anchor.host === window.location.host) {
					anchor.removeEventListener('click', handleNavigation)
				}
			}
		}
	})

	return (
		<div
			ref={wysiwygEl}
			dangerouslySetInnerHTML={{
				__html: content,
			}}
		></div>
	)
})

const WysiwygBrick = React.memo(({ ...data }) => {
	return (
		<BrickContainer data={data}>
			<div {...bem('')} id={'wysiwyg-brick'}>
				<Container>
					<div {...bem('container')}>
						<Wysiwyg content={data.wysiwyg.data} />
					</div>
				</Container>
			</div>
		</BrickContainer>
	)
})

export default WysiwygBrick
