import './AppBrick.scss'

import React from 'react'
import BEMHelper from 'react-bem-helper'
import useSetting from '@src/hooks/useSetting'
import Container from '@material-ui/core/Container'
import Link from '@src/components/Link'
import BrickContainer from '@src/components/bricks/BrickContainer'
import Thumbnails from '@src/components/Thumbnails'

const bem = new BEMHelper('app-brick')

const AppBrick = ({ ...data }) => {
	const getSetting = useSetting()
	const cdnHost = getSetting('CDNHost', '')

	return (
		<BrickContainer data={data}>
			<div {...bem('')} id={'app-brick'}>
				<div {...bem('outer-container')}>
					<Container>
						<div {...bem('container')}>
							<div {...bem('image-container')}>
								<Thumbnails data={data} bemClass={'app-brick'} />
							</div>

							<div {...bem('text-container')}>
								<div {...bem('text')}>
									<div {...bem('heading')}>
										<h2 className='h1'>{data.title}</h2>
									</div>
									<p>{data.text}</p>
									<div {...bem('links')}>
										{data.linkAppStore &&
											data.imageAppStore &&
											data.imageAppStoreAlt && (
												<Link to={data.linkAppStore}>
													<img
														src={`${cdnHost}${data.imageAppStore}`}
														alt={data.imageAppStoreAlt}
													/>
												</Link>
											)}
										{data.linkGooglePlay &&
											data.imageGooglePlay &&
											data.imageGooglePlayAlt && (
												<Link to={data.linkGooglePlay}>
													<img
														src={`${cdnHost}${data.imageGooglePlay}`}
														alt={data.imageGooglePlayAlt}
													/>
												</Link>
											)}
									</div>
								</div>
							</div>
						</div>
					</Container>
				</div>
			</div>
		</BrickContainer>
	)
}

export default AppBrick
