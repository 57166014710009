import React from 'react'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import linksBrickResolve from './brickresolvers/links'
import wysiwygBrickResolve from './brickresolvers/wysiwyg'
import stepBrickResolve from './brickresolvers/step'
import accordionBrickResolve from './brickresolvers/accordion'
import informationBrickResolve from './brickresolvers/information'
import imageAndTextBrickResolve from './brickresolvers/imageAndText'
import newImageAndTextBrickResolve from './brickresolvers/newImageAndText'
import stationFinderBrickResolve from './brickresolvers/stationFinder'
import snippetBrickResolve from './brickresolvers/snippet'
import appBrickResolve from './brickresolvers/app'
import newAppBrickResolve from './brickresolvers/newApp'
import carsBrickResolve from './brickresolvers/cars'
import newCarsBrickResolve from './brickresolvers/newCars'
import membershipBrickResolve from './brickresolvers/membership'
import carBrickResolve from './brickresolvers/car'
import newCarBrickResolve from './brickresolvers/newCar'
import cookiesBrickResolve from './brickresolvers/cookies'
import howToBrickResolve from './brickresolvers/howTo'
import longHowToBrickResolve from './brickresolvers/longHowTo'
import quoteBrickResolve from './brickresolvers/quote'
import uspBrickResolve from './brickresolvers/usp'
import heroBrickResolve from './brickresolvers/hero'
import videoBrickResolve from './brickresolvers/video'
import leadBrickResolve from './brickresolvers/lead'
import leadHeroImageBrickResolve from './brickresolvers/leadHeroImage'
import membershipIntroBrickResolve from './brickresolvers/membershipIntro'
import mapBrickResolve from './brickresolvers/map'
import columnCheckBrickResolve from './brickresolvers/columnCheck'

export const findEditableByName = (editables, name) => {
	return editables.find((editable) => editable.name === name)
}

export const BrickResolver = ({ pageContext }) => {
	let bricksList = []

	if (
		!pageContext ||
		!pageContext.data ||
		(pageContext.data && pageContext.data.blocks.length === 0)
	) {
		return (
			<div style={{ textAlign: 'center' }}>
				<CircularProgress />
			</div>
		)
	}

	const pushBrick = (currentBrickList) => {
		if (currentBrickList.length >= 3) {
			bricksList.push(
				<Grid grid={3} key={`grid-${bricksList.length}`}>
					{currentBrickList}
				</Grid>,
			)
		} else {
			bricksList.push(currentBrickList)
		}
	}

	pageContext.data.blocks.forEach((block) => {
		if (!block.bricks || block.bricks.length === 0) {
			return
		}

		let lastBrickType = null
		let currentBrickList = []

		block.bricks.forEach((brick) => {
			let currentBrick = null

			let brickType = brick.type

			switch (brickType) {
				case 'step-brick':
					currentBrick = stepBrickResolve(brick)
					break
				case 'link-brick':
					currentBrick = linksBrickResolve(brick)
					break
				case 'wysiwyg-brick':
					currentBrick = wysiwygBrickResolve(brick)
					break
				case 'accordion-brick':
					currentBrick = accordionBrickResolve(brick)
					break
				case 'information-brick':
					currentBrick = informationBrickResolve(brick)
					break
				case 'station-finder-brick':
					currentBrick = stationFinderBrickResolve(brick)
					break
				case 'image-and-text-brick':
					currentBrick = imageAndTextBrickResolve(brick)
					break
				case 'new-image-and-text-brick':
					currentBrick = newImageAndTextBrickResolve(brick)
					break
				case 'app-brick':
					currentBrick = appBrickResolve(brick)
					break
				case 'new-app-brick':
					currentBrick = newAppBrickResolve(brick)
					break
				case 'cars-brick':
					currentBrick = carsBrickResolve(brick)
					break
				case 'new-cars-brick':
					currentBrick = newCarsBrickResolve(brick)
					break
				case 'snippet':
					currentBrick = snippetBrickResolve(brick)
					break
				case 'membership-brick':
					currentBrick = membershipBrickResolve(brick)
					break
				case 'car-brick':
					currentBrick = carBrickResolve(brick)
					break
				case 'new-car-brick':
					currentBrick = newCarBrickResolve(brick)
					break
				case 'cookies-brick':
					currentBrick = cookiesBrickResolve(brick)
					break
				case 'how-to-brick':
					currentBrick = howToBrickResolve(brick)
					break
				case 'long-how-to-brick':
					currentBrick = longHowToBrickResolve(brick)
					break
				case 'quote-brick':
					currentBrick = quoteBrickResolve(brick)
					break
				case 'usp-brick':
					currentBrick = uspBrickResolve(brick)
					break
				case 'hero-brick':
					currentBrick = heroBrickResolve(brick)
					break
				case 'video-brick':
					currentBrick = videoBrickResolve(brick)
					break
				case 'lead-brick':
					currentBrick = leadBrickResolve(brick)
					break
				case 'lead-hero-image-brick':
					currentBrick = leadHeroImageBrickResolve(brick)
					break
				case 'membership-intro-brick':
					currentBrick = membershipIntroBrickResolve(brick)
					break
				case 'map-brick':
					currentBrick = mapBrickResolve(brick)
					break
				case 'column-check-brick':
					currentBrick = columnCheckBrickResolve(brick)
					break
				default:
				// throw exception or just ignore case when brick is not found
			}

			if (!currentBrick) {
				return
			}

			if (lastBrickType !== null && lastBrickType !== brickType) {
				pushBrick(currentBrickList)

				currentBrickList = []
			}
			currentBrickList.push(currentBrick)
			lastBrickType = brickType
		})

		pushBrick(currentBrickList)
	})

	return <>{bricksList}</>
}
