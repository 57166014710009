import './QuoteBrick.scss'
import React, { useEffect, useState } from 'react'
import BEMHelper from 'react-bem-helper'
import Container from '@material-ui/core/Container'
import BrickContainer from '@src/components/bricks/BrickContainer'
import { smallDesktopSize } from '@src/javascript/variables'

const bem = new BEMHelper('quote-brick')

const QuoteBrick = ({ ...data }) => {
	const [isDesktop, setIsDesktop] = useState(true)

	useEffect(() => {
		const handleResize = () => {
			setIsDesktop(
				typeof window === 'undefined' || window.innerWidth > smallDesktopSize,
			)
		}
		window.addEventListener('resize', handleResize)
		setIsDesktop(
			typeof window === 'undefined' || window.innerWidth > smallDesktopSize,
		)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<BrickContainer data={data}>
			<div {...bem('')} id={'quote-brick'}>
				<Container maxWidth={data.bigGrid && 'md'}>
					<div
						{...(data.bigGrid && isDesktop
							? { ...bem('container', 'big-grid') }
							: { ...bem('container') })}
					>
						{data.quote && <h4 className='quote'>{data.quote}</h4>}
						{data.quoteBy && <h5>{data.quoteBy}</h5>}
					</div>
				</Container>
			</div>
		</BrickContainer>
	)
}

export default QuoteBrick
