import './AccordionBrick.scss'

import React from 'react'
import BEMHelper from 'react-bem-helper'
import {
	Container,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Arrow from '@material-ui/icons/KeyboardArrowDown'
import BrickContainer from '@src/components/bricks/BrickContainer'

const bem = new BEMHelper('accordion-brick')

const useStyles = makeStyles({
	expanded: {
		'&$expanded': {
			margin: 0,
		},
	},
})

const AccordionBrick = ({ ...data }) => {
	const classes = useStyles()

	return (
		<BrickContainer data={data}>
			<div {...bem('')} id={'accordion-brick'}>
				<Container>
					<div {...bem('container')}>
						{data.brickTitle && <h2 {...bem('title')}>{data.brickTitle}</h2>}
						<div {...bem('content')}>
							{Object.values(data.blockEditables).map((item) => {
								if (item.title && item.text) {
									return (
										<ExpansionPanel
											square
											className={classes.root}
											classes={{ expanded: classes.expanded }}
											key={item.title}
										>
											<ExpansionPanelSummary expandIcon={<Arrow />}>
												<h3 className='paragraph'>{item.title}</h3>
											</ExpansionPanelSummary>
											<ExpansionPanelDetails>{item.text}</ExpansionPanelDetails>
										</ExpansionPanel>
									)
								}
								return null
							})}
						</div>
					</div>
				</Container>
			</div>
		</BrickContainer>
	)
}

export default AccordionBrick
