const degreesToRadians = (degrees) => {
	return (degrees * Math.PI) / 180
}

export const distanceInMeterBetweenEarthCoordinates = (
	lat1,
	lon1,
	lat2,
	lon2,
) => {
	const earthRadiusKm = 6371

	const dLat = degreesToRadians(lat2 - lat1)
	const dLon = degreesToRadians(lon2 - lon1)

	lat1 = degreesToRadians(lat1)
	lat2 = degreesToRadians(lat2)

	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
	return parseInt(earthRadiusKm * c) * 1000
}

export const getStationsClosestToLocation = (
	{ lat, lng },
	stations,
	amount,
) => {
	const calculatedData = []
	for (let i = 0; i < stations.length; i++) {
		const distance = distanceInMeterBetweenEarthCoordinates(
			lat,
			lng,
			stations[i].location.lat,
			stations[i].location.lng,
		)
		calculatedData.push({ stationId: stations[i].id, distance })
	}
	calculatedData.sort((a, b) => (a.distance > b.distance ? 1 : -1))
	if (amount) {
		return calculatedData.splice(0, amount)
	}
	return calculatedData
}

export const readableDistance = (distanceInMeters) => {
	if (distanceInMeters / 1000 > 1) {
		return `${(distanceInMeters / 1000).toFixed(0)} km`
	} else {
		return `${distanceInMeters} m`
	}
}
