import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import NewCarBrick from '@src/components/bricks/NewCarBrick'
import React from 'react'

const newCarBrickResolve = (brick) => {
	const margins = getMarginsFromBricks(brick)

	const vehicletypeEditable = findEditableByName(brick.editables, 'vehicletype')
	if (!vehicletypeEditable) {
		return
	}

	const blockEditablesArray = findEditableByName(
		brick.editables,
		'contentblock',
	)

	var blockEditables = {}
	const arrayLength =
		blockEditablesArray && blockEditablesArray.objects
			? blockEditablesArray.objects.length
			: 0

	for (var i = 0; i < arrayLength; i++) {
		const editable = blockEditablesArray.objects[i]

		const editableIndexRegex = /contentblock:([0-9]+)/i
		const regexResult = editableIndexRegex.exec(editable.name)
		const editableIndex = parseInt(regexResult[1])

		const informationTitleRegex = /informationTitle/gi
		const isInformationTitle = informationTitleRegex.test(editable.name)

		const informationTextRegex = /informationText/gi
		const isInformationText = informationTextRegex.test(editable.name)

		if (blockEditables['information' + editableIndex] == null) {
			blockEditables['information' + editableIndex] = {}
		}

		if (isInformationTitle) {
			blockEditables['information' + editableIndex] = {
				title: editable.value,
				...blockEditables['information' + editableIndex],
			}
		} else if (isInformationText) {
			blockEditables['information' + editableIndex] = {
				text: editable.value,
				...blockEditables['information' + editableIndex],
			}
		}
	}

	const blockEditablesExpandableArray = findEditableByName(
		brick.editables,
		'contentblockExpandable',
	)

	var blockEditablesExpandable = {}
	const expandableArrayLength =
		blockEditablesExpandableArray && blockEditablesExpandableArray.objects
			? blockEditablesExpandableArray.objects.length
			: 0

	for (var x = 0; x < expandableArrayLength; x++) {
		const editable = blockEditablesExpandableArray.objects[x]

		const editableIndexRegex = /contentblockExpandable:([0-9]+)/i
		const regexResult = editableIndexRegex.exec(editable.name)
		const editableIndex = parseInt(regexResult[1])

		const informationTitleRegex = /informationTitle/gi
		const isInformationTitle = informationTitleRegex.test(editable.name)

		const informationTextRegex = /informationText/gi
		const isInformationText = informationTextRegex.test(editable.name)

		if (blockEditablesExpandable['information' + editableIndex] == null) {
			blockEditablesExpandable['information' + editableIndex] = {}
		}

		if (isInformationTitle) {
			blockEditablesExpandable['information' + editableIndex] = {
				title: editable.value,
				...blockEditablesExpandable['information' + editableIndex],
			}
		} else if (isInformationText) {
			blockEditablesExpandable['information' + editableIndex] = {
				text: editable.value,
				...blockEditablesExpandable['information' + editableIndex],
			}
		}
	}

	const data = {
		margins,
		blockEditables,
		blockEditablesExpandable,
		flex: findEditableByName(brick.editables, 'flex')
			? findEditableByName(brick.editables, 'flex').value
			: null,
		business: findEditableByName(brick.editables, 'business')
			? findEditableByName(brick.editables, 'business').value
			: null,
		image: findEditableByName(brick.editables, 'image').value,
		link: {
			text: findEditableByName(brick.editables, 'link').text,
			target: findEditableByName(brick.editables, 'link').value,
		},
		vehicletype: vehicletypeEditable.value,
	}

	return <NewCarBrick {...data} key={brick.key}></NewCarBrick>
}

export default newCarBrickResolve
