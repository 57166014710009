import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import getThumbnailsFromBricks from '@src/utils/getThumbnailsFromBricks'
import StationFinderBrick from '@src/components/bricks/StationFinderBrick'
import React from 'react'

const stationFinderBrickResolve = (brick) => {
	const images = getThumbnailsFromBricks(brick, 'header-image')
	const margins = getMarginsFromBricks(brick)
	const data = {
		margins,
		images: images,
		imageAlt: findEditableByName(brick.editables, 'header-image').title,
		text: findEditableByName(brick.editables, 'text').value,
	}
	return <StationFinderBrick {...data} key={brick.key}></StationFinderBrick>
}

export default stationFinderBrickResolve
