import './HowToBrick.scss'

import React, { useState, useEffect } from 'react'
import BEMHelper from 'react-bem-helper'
import Slider from 'react-slick'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import useSetting from '@src/hooks/useSetting'
import BrickContainer from '@src/components/bricks/BrickContainer'
import { tabletSize } from '@src/javascript/variables'
import { smallDesktopSize } from '@src/javascript/variables'
import Link from '@src/components/Link'

const bem = new BEMHelper('how-to-brick')

const HowToBrick = ({ ...data }) => {
	const getSetting = useSetting()
	const cdnHost = getSetting('CDNHost', '')
	const [isMobile, setIsMobile] = useState(true)
	const [isDesktop, setIsDesktop] = useState(true)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(
				typeof window === 'undefined' || window.innerWidth < tabletSize,
			)
			setIsDesktop(
				typeof window === 'undefined' || window.innerWidth > smallDesktopSize,
			)
		}
		window.addEventListener('resize', handleResize)
		setIsMobile(typeof window === 'undefined' || window.innerWidth < tabletSize)
		setIsDesktop(
			typeof window === 'undefined' || window.innerWidth > smallDesktopSize,
		)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const div = ({ children }) => (
		<div
			{...(data.bigGrid && isDesktop
				? { ...bem('content-container', 'big-grid') }
				: { ...bem('content-container') })}
		>
			{children}
		</div>
	)
	const ContainerComponent = isMobile ? Slider : div
	const settings = {
		dots: true,
		infinite: false,
		variableWidth: true,
		arrows: false,
	}

	return (
		<BrickContainer data={data}>
			<div {...bem('')} id={'how-to-brick'}>
				<Container maxWidth={data.bigGrid && isDesktop && 'md'}>
					<div
						{...(data.bigGrid && isDesktop
							? { ...bem('container', 'big-grid') }
							: { ...bem('container') })}
					>
						<div {...bem('container-text')}>
							{data.title && <h2 className='h1'>{data.title}</h2>}
							{data.text && <p className='large'>{data.text}</p>}
						</div>
						<ContainerComponent {...settings}>
							{Object.values(data.blockEditables).map((item) => {
								if (item.title || item.text || item.image) {
									return (
										<div
											key={item.title}
											{...(data.bigGrid && isDesktop
												? { ...bem('block', 'big-grid') }
												: { ...bem('block') })}
										>
											{item.image && item.imageAlt && (
												<img
													src={`${cdnHost}${item.image}`}
													alt={item.imageAlt}
													className={item.notActive ? 'not-active' : 'active'}
												/>
											)}

											<h3 className='h2'>{item.title}</h3>
											<p className='large'>{item.text}</p>
										</div>
									)
								}
								return null
							})}
						</ContainerComponent>
						{data.link.target && data.link.linkText && (
							<div {...bem('button')}>
								<Button
									fullWidth
									color='secondary'
									variant='contained'
									size='large'
									to={data.link.target}
									component={Link}
								>
									{data.link.linkText}
								</Button>
							</div>
						)}
					</div>
				</Container>
			</div>
		</BrickContainer>
	)
}

export default HowToBrick
