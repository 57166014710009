import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import InformationBrick from '@src/components/bricks/InformationBrick'
import React from 'react'

const informationBrickResolve = (brick) => {
	const blockEditablesArray = findEditableByName(
		brick.editables,
		'contentblock',
	)
	var blockEditables = {}
	const arrayLength =
		blockEditablesArray && blockEditablesArray.objects
			? blockEditablesArray.objects.length
			: 0

	for (var i = 0; i < arrayLength; i++) {
		const editable = blockEditablesArray.objects[i]

		const editableIndexRegex = /contentblock:([0-9]+)/i
		const regexResult = editableIndexRegex.exec(editable.name)
		const editableIndex = parseInt(regexResult[1])

		const informationImageRegex = /informationImage/gi
		const isInformationImage = informationImageRegex.test(editable.name)

		const informationTitleRegex = /informationTitle/gi
		const isInformationTitle = informationTitleRegex.test(editable.name)

		const informationTextRegex = /informationText/gi
		const isInformationText = informationTextRegex.test(editable.name)

		if (blockEditables['information' + editableIndex] == null) {
			blockEditables['information' + editableIndex] = {}
		}

		if (isInformationImage) {
			blockEditables['information' + editableIndex] = Object.assign(
				{ image: editable.value },
				blockEditables['information' + editableIndex],
			)
			blockEditables['information' + editableIndex] = Object.assign(
				{ imageAlt: editable.title },
				blockEditables['information' + editableIndex],
			)
		} else if (isInformationTitle) {
			blockEditables['information' + editableIndex] = Object.assign(
				{ title: editable.value },
				blockEditables['information' + editableIndex],
			)
		} else if (isInformationText) {
			blockEditables['information' + editableIndex] = Object.assign(
				{ text: editable.value },
				blockEditables['information' + editableIndex],
			)
		}
	}

	const margins = getMarginsFromBricks(brick)

	const data = {
		margins,
		blockEditables,
		link: {
			linkText: findEditableByName(brick.editables, 'link').text,
			target: findEditableByName(brick.editables, 'link').value,
		},
	}

	return <InformationBrick {...data} key={brick.key}></InformationBrick>
}

export default informationBrickResolve
