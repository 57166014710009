import React from 'react'
import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import getThumbnailsFromBricks from '@src/utils/getThumbnailsFromBricks'

import LeadHeroImageBrick from '@src/components/bricks/LeadHeroImageBrick'

export default function leadHeroImageBrickResolve(brick) {
	const images = getThumbnailsFromBricks(brick, 'image')
	const margins = getMarginsFromBricks(brick)

	const data = {
		margins,
		images: images,
		imageAlt: findEditableByName(brick.editables, 'image').title,
		area: findEditableByName(brick.editables, 'area').value,
		titleTag: findEditableByName(brick.editables, 'titleTag').value,
		secondTitleTag: findEditableByName(brick.editables, 'secondTitleTag').value,
		formUrl: findEditableByName(brick.editables, 'formUrl').value,
		title: findEditableByName(brick.editables, 'title').value,
		text: findEditableByName(brick.editables, 'text').value,
		secondTitle: findEditableByName(brick.editables, 'secondTitle').value,
		privacyText: findEditableByName(brick.editables, 'privacyText').value,
		buttonText: findEditableByName(brick.editables, 'buttonText').value,
	}

	return <LeadHeroImageBrick {...data} key={brick.key} />
}
