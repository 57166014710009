import './HeroBrick.scss'
import React from 'react'
import BEMHelper from 'react-bem-helper'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import BrickContainer from '@src/components/bricks/BrickContainer'
import Thumbnails from '@src/components/Thumbnails'
import Link from '@src/components/Link'
import Wysiwyg from '@src/components/bricks/editables/Wysiwyg'

const bem = new BEMHelper('hero-brick')

const HeroBrick = ({ ...data }) => {
	return (
		<BrickContainer data={data}>
			<div {...bem('')} id={'hero-brick'}>
				<div {...bem('image-container')}>
					<Thumbnails
						data={data}
						bemClass={'hero-brick'}
						overlay={data.imageOverlay ? true : false}
					/>
				</div>
				<div {...bem('container')}>
					<Container>
						<div {...bem('content-container')}>
							<div {...bem('text-container')}>
								{data.title && (
									<div {...bem('title')}>
										<h2 className='display'>{data.title}</h2>
									</div>
								)}
								<div {...bem('text')}>
									<Wysiwyg content={data.wysiwygText.data} />
								</div>
							</div>
							<div {...bem('buttons-container')}>
								{data.link.target && data.link.linkText && (
									<div {...bem('button')}>
										<Button
											className={
												data.buttonColor === 'Orange' ? 'ButtonOrange' : null
											}
											aria-label={data.link.linkText}
											color={
												data.buttonColor === 'White' ? 'secondary' : 'primary'
											}
											variant={'contained'}
											size='large'
											to={data.link.target}
											component={Link}
											fullWidth
										>
											{data.link.linkText}
										</Button>
									</div>
								)}
								{data.secondLink.target && data.secondLink.linkText && (
									<div {...bem('button')}>
										<Button
											className={
												data.secondButtonColor === 'Orange'
													? 'ButtonOrange'
													: null
											}
											aria-label={data.secondLink.linkText}
											color={
												data.secondButtonColor === 'White'
													? 'secondary'
													: 'primary'
											}
											variant={'contained'}
											size='large'
											to={data.secondLink.target}
											component={Link}
											fullWidth
										>
											{data.secondLink.linkText}
										</Button>
									</div>
								)}
							</div>
							<div {...bem('second-text')}>
								<Wysiwyg content={data.wysiwygSecondText.data} />
							</div>
						</div>
					</Container>
				</div>
			</div>
		</BrickContainer>
	)
}

export default HeroBrick
