import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import QuoteBrick from '@src/components/bricks/QuoteBrick'
import React from 'react'

const quoteBrickResolve = (brick) => {
	const margins = getMarginsFromBricks(brick)

	const data = {
		margins,
		bigGrid: findEditableByName(brick.editables, 'big-grid').value,
		quote: findEditableByName(brick.editables, 'quote').value,
		quoteBy: findEditableByName(brick.editables, 'quote-by').value,
	}

	return <QuoteBrick {...data} key={brick.key}></QuoteBrick>
}

export default quoteBrickResolve
