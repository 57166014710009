import './CarAttribute.scss'
import React from 'react'
import BEMHelper from 'react-bem-helper'
import useTranslations from '@src/hooks/useTranslations'
import { ReactComponent as CarIcon } from '@src/assets/icons/car.svg'
import { ReactComponent as TowbarIcon } from '@src/assets/icons/towbar.svg'
import { ReactComponent as SeatIcon } from '@src/assets/icons/seat.svg'

const bem = new BEMHelper('car-attribute')

export const CAR_ICON = {
	small: CarIcon,
	medium: CarIcon,
	large: CarIcon,
	van: CarIcon,
	luxury: CarIcon,
	hydrogen: CarIcon,
	mediumSuv: CarIcon,
	towbar: TowbarIcon,
	seats: SeatIcon,
}

const CarAttribute = ({ type, numberOfSeats }) => {
	const t = useTranslations()
	const Icon = CAR_ICON[type]
	return Icon ? (
		<div {...bem('')}>
			<Icon />
			<p className='tiny'>{t(`kinto.carattribute-${type}`, [numberOfSeats])}</p>
		</div>
	) : (
		<></>
	)
}

export default CarAttribute
