import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import getThumbnailsFromBricks from '@src/utils/getThumbnailsFromBricks'
import HeroBrick from '@src/components/bricks/HeroBrick'
import React from 'react'

const heroBrickResolve = (brick) => {
	const images = getThumbnailsFromBricks(brick, 'image')
	const margins = getMarginsFromBricks(brick)

	const data = {
		margins,
		title: findEditableByName(brick.editables, 'title').value,
		wysiwygText: {
			data: findEditableByName(brick.editables, 'wysiwygText')
				? findEditableByName(brick.editables, 'wysiwygText').value
				: null,
		},
		images: images,
		imageAlt: findEditableByName(brick.editables, 'image').title,
		imageOverlay: findEditableByName(brick.editables, 'imageOverlay')
			? findEditableByName(brick.editables, 'imageOverlay').value
			: null,
		buttonColor: findEditableByName(brick.editables, 'buttonColor')
			? findEditableByName(brick.editables, 'buttonColor').value
			: null,
		link: {
			linkText: findEditableByName(brick.editables, 'link').text,
			target: findEditableByName(brick.editables, 'link').value,
		},
		secondButtonColor: findEditableByName(brick.editables, 'buttonColor2')
			? findEditableByName(brick.editables, 'buttonColor2').value
			: null,
		secondLink: {
			linkText: findEditableByName(brick.editables, 'link2')
				? findEditableByName(brick.editables, 'link2').text
				: null,
			target: findEditableByName(brick.editables, 'link2')
				? findEditableByName(brick.editables, 'link2').value
				: null,
		},
		wysiwygSecondText: {
			data: findEditableByName(brick.editables, 'wysiwygSecondText')
				? findEditableByName(brick.editables, 'wysiwygSecondText').value
				: null,
		},
	}

	return <HeroBrick {...data} key={brick.key}></HeroBrick>
}

export default heroBrickResolve
