import './UspBrick.scss'

import React, { useEffect, useState } from 'react'
import BEMHelper from 'react-bem-helper'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import BrickContainer from '@src/components/bricks/BrickContainer'
import Thumbnails from '@src/components/Thumbnails'
import Link from '@src/components/Link'
import { smallDesktopSize } from '@src/javascript/variables'

const bem = new BEMHelper('usp-brick')

const UspBrick = ({ ...data }) => {
	const NoWrapper = ({ children }) => <>{children}</>
	const [isDesktop, setIsDesktop] = useState(true)

	useEffect(() => {
		const handleResize = () => {
			setIsDesktop(
				typeof window === 'undefined' || window.innerWidth > smallDesktopSize,
			)
		}
		window.addEventListener('resize', handleResize)
		setIsDesktop(
			typeof window === 'undefined' || window.innerWidth > smallDesktopSize,
		)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<BrickContainer data={data}>
			<div
				{...(data.bigGrid && data.buttonLink && data.buttonText && isDesktop
					? { ...bem('', 'big-grid-button') }
					: data.bigGrid && isDesktop
					? { ...bem('', 'big-grid') }
					: { ...bem('') })}
				id={'usp-brick'}
			>
				<Container maxWidth={data.bigGrid && isDesktop && 'md'}>
					<div
						{...(data.bigGrid && isDesktop
							? { ...bem('container', 'big-grid') }
							: { ...bem('container') })}
					>
						{data.bigGrid && isDesktop && (
							<div {...bem('image-container')}>
								{data.images.length > 0 &&
									data.imageAlt &&
									!(data.buttonLink || data.buttonText) && (
										<Thumbnails data={data} bemClass={'usp-brick'} />
									)}
							</div>
						)}
						<div {...bem('container-content')}>
							{Object.values(data.blockEditables)
								.filter((item) => item.title || item.text)
								.map((item) => {
									const Wrapper = item.link ? Link : NoWrapper
									return (
										<div
											key={item.title}
											{...(data.bigGrid && isDesktop
												? { ...bem('block', 'big-grid') }
												: { ...bem('block') })}
										>
											<Wrapper to={item.link}>
												<div {...bem('text-container')}>
													<h3>{item.title}</h3>
													<p {...bem('text')}>{item.text}</p>
												</div>
											</Wrapper>
										</div>
									)
								})}
						</div>
					</div>
					{data.buttonText && data.buttonLink && (
						<div {...bem('button')}>
							<Button
								fullWidth
								color='primary'
								variant='contained'
								to={data.buttonLink}
								component={Link}
							>
								{data.buttonText}
							</Button>
						</div>
					)}
				</Container>
			</div>
		</BrickContainer>
	)
}

export default UspBrick
