import './LongHowToBrick.scss'

import React from 'react'
import BEMHelper from 'react-bem-helper'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import NavigateNextIcon from '@material-ui/icons/ArrowForwardIos'
import useSetting from '@src/hooks/useSetting'
import BrickContainer from '@src/components/bricks/BrickContainer'
import Link from '@src/components/Link'

const bem = new BEMHelper('long-how-to-brick')
const NoWrapper = ({ children }) => <>{children}</>

const LongHowToBrick = ({ ...data }) => {
	const getSetting = useSetting()
	const cdnHost = getSetting('CDNHost', '')

	return (
		<BrickContainer data={data}>
			<div {...bem('')} id={'long-how-to-brick'}>
				<Container>
					<div {...bem('container-top')}>
						{data.title && <h2 className='h1'>{data.title}</h2>}
						{data.text && <p className='large'>{data.text}</p>}
						{data.button.target && data.button.buttonText && (
							<Button
								{...bem('button')}
								aria-label={data.button.buttonText}
								variant='outlined'
								color='primary'
								to={data.button.target}
								endIcon={<NavigateNextIcon />}
								component={Link}
							>
								{data.button.buttonText}
							</Button>
						)}
					</div>

					{Object.values(data.blockEditables)
						.filter((item) => item.title || item.text || item.image)
						.map((item) => {
							const Wrapper = item.link ? Link : NoWrapper
							return (
								<div key={item.title} {...bem('block')}>
									<Wrapper to={item.link}>
										<div {...bem('title')}>
											{item.image && item.imageAlt && (
												<img
													src={`${cdnHost}${item.image}`}
													alt={item.imageAlt}
												/>
											)}
											<h3>{item.title}</h3>
										</div>
										<div {...bem('text')}>
											<p>{item.text}</p>
										</div>
									</Wrapper>
								</div>
							)
						})}

					{data.link.target && data.link.linkText && (
						<Link {...bem('link')} href={data.link.target}>
							<p>{data.link.linkText}</p>
							<NavigateNextIcon />
						</Link>
					)}
				</Container>
			</div>
		</BrickContainer>
	)
}

export default LongHowToBrick
