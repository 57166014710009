import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import StepBrick from '@src/components/bricks/StepBrick'
import React from 'react'

const stepBrickResolve = (brick) => {
	const blockEditablesArray = findEditableByName(
		brick.editables,
		'stepbrickblock',
	)
	var blockEditables = {}
	const arrayLength =
		blockEditablesArray && blockEditablesArray.objects
			? blockEditablesArray.objects.length
			: 0

	// editables array with name like content:2.contentblock:1.stepText
	for (var i = 0; i < arrayLength; i++) {
		const editable = blockEditablesArray.objects[i]

		const editableIndexRegex = /stepbrickblock:([0-9]+)/i
		const regexResult = editableIndexRegex.exec(editable.name)
		const editableIndex = parseInt(regexResult[1])

		const textRegex = /stepText/gi
		const isText = textRegex.test(editable.name)

		if (blockEditables['step' + editableIndex] == null) {
			blockEditables['step' + editableIndex] = {}
		}

		if (isText) {
			blockEditables['step' + editableIndex] = Object.assign(
				{ text: editable.value },
				blockEditables['step' + editableIndex],
			)
		} else {
			blockEditables['step' + editableIndex] = Object.assign(
				{ title: editable.value },
				blockEditables['step' + editableIndex],
			)
		}
	}

	const margins = getMarginsFromBricks(brick)

	const data = {
		margins,
		title: findEditableByName(brick.editables, 'title').value,
		blockEditables,
		link: {
			linkText: findEditableByName(brick.editables, 'link').text,
			target: findEditableByName(brick.editables, 'link').value,
		},
	}

	return <StepBrick {...data} key={brick.key}></StepBrick>
}

export default stepBrickResolve
