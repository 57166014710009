import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import getThumbnailsFromBricks from '@src/utils/getThumbnailsFromBricks'
import NewAppBrick from '@src/components/bricks/NewAppBrick'
import React from 'react'

const newAppBrickResolve = (brick) => {
	const images = getThumbnailsFromBricks(brick, 'image')
	const margins = getMarginsFromBricks(brick)

	const data = {
		margins,
		images,
		imageAlt: findEditableByName(brick.editables, 'image').title,
		title: findEditableByName(brick.editables, 'title').value,
		text: findEditableByName(brick.editables, 'text').value,
		linkAppStore: findEditableByName(brick.editables, 'linkAppStore').value,
		imageAppStore: findEditableByName(brick.editables, 'imageAppStore').value,
		imageAppStoreAlt: findEditableByName(brick.editables, 'imageAppStore')
			.title,
		linkGooglePlay: findEditableByName(brick.editables, 'linkGooglePlay').value,
		imageGooglePlay: findEditableByName(brick.editables, 'imageGooglePlay')
			.value,
		imageGooglePlayAlt: findEditableByName(brick.editables, 'imageGooglePlay')
			.title,
		bigGrid: findEditableByName(brick.editables, 'big-grid').value,
	}

	return <NewAppBrick {...data} key={brick.key}></NewAppBrick>
}

export default newAppBrickResolve
