import './LinkBrick.scss'

import React from 'react'
import BEMHelper from 'react-bem-helper'
import { Button, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Arrow from '@material-ui/icons/ArrowForwardIos'
import BrickContainer from '@src/components/bricks/BrickContainer'
import Link from '@src/components/Link'

const bem = new BEMHelper('link-brick')

const useStyles = makeStyles({
	root: {
		padding: '1rem',
		justifyContent: 'space-between',
	},
})

const LinkBrick = ({ ...data }) => {
	const classes = useStyles()

	return (
		<BrickContainer data={data}>
			<div {...bem('')} id={'link-brick'}>
				<Container>
					<div {...bem('container')}>
						{Object.values(data).map((item, i) => {
							if (item.target && item.text) {
								return (
									<Button
										key={item.text}
										color='primary'
										variant='outlined'
										fullWidth
										endIcon={<Arrow fontSize='small' />}
										className={classes.root}
										to={item.target}
										component={Link}
									>
										{item.text}
									</Button>
								)
							}
							return null
						})}
					</div>
				</Container>
			</div>
		</BrickContainer>
	)
}

export default LinkBrick
