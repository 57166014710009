import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import CarBrick from '@src/components/bricks/CarBrick'
import React from 'react'

const carBrickResolve = (brick) => {
	const margins = getMarginsFromBricks(brick)

	const vehicletypeEditable = findEditableByName(brick.editables, 'vehicletype')
	if (!vehicletypeEditable) {
		return
	}

	const data = {
		margins,
		link: {
			text: findEditableByName(brick.editables, 'link').text,
			target: findEditableByName(brick.editables, 'link').value,
		},
		vehicletype: vehicletypeEditable.value,
	}

	return <CarBrick {...data} key={brick.key}></CarBrick>
}

export default carBrickResolve
