import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import MembershipBrick from '@src/components/bricks/MembershipBrick'
import React from 'react'

const membershipBrickResolve = (brick) => {
	const margins = getMarginsFromBricks(brick)

	const vehicletypeSmall = findEditableByName(
		brick.editables,
		'vehicletypeSmall',
	)
	if (!vehicletypeSmall) {
		return
	}

	const vehicletypeMedium = findEditableByName(
		brick.editables,
		'vehicletypeMedium',
	)
	if (!vehicletypeMedium) {
		return
	}

	const vehicletypeLarge = findEditableByName(
		brick.editables,
		'vehicletypeLarge',
	)
	if (!vehicletypeLarge) {
		return
	}

	const vehicletypeVan = findEditableByName(brick.editables, 'vehicletypeVan')
	if (!vehicletypeVan) {
		return
	}

	const vehicletypeLuxury = findEditableByName(
		brick.editables,
		'vehicletypeLuxury',
	)
	if (!vehicletypeLuxury) {
		return
	}

	const vehicletypeHydrogen = findEditableByName(
		brick.editables,
		'vehicletypeHydrogen',
	)
	if (!vehicletypeHydrogen) {
		return
	}

	const vehicletypeMediumSuv = findEditableByName(
		brick.editables,
		'vehicletypeMediumSuv',
	)
	if (!vehicletypeMediumSuv) {
		return
	}

	const data = {
		margins,
		title: findEditableByName(brick.editables, 'title').value,
		text: findEditableByName(brick.editables, 'text').value,
		carText: findEditableByName(brick.editables, 'carText').value,
		vehicletypes: {
			vehicletypeSmall: vehicletypeSmall.value,
			vehicletypeMedium: vehicletypeMedium.value,
			vehicletypeLarge: vehicletypeLarge.value,
			vehicletypeVan: vehicletypeVan.value,
			vehicletypeLuxury: vehicletypeLuxury.value,
			vehicletypeHydrogen: vehicletypeHydrogen.value,
			vehicletypeMediumSuv: vehicletypeMediumSuv.value,
		},
		defaultCar: findEditableByName(brick.editables, 'defaultCar').value,
		brickType: findEditableByName(brick.editables, 'brickType').value,
	}

	return <MembershipBrick {...data} key={brick.key}></MembershipBrick>
}

export default membershipBrickResolve
