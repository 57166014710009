import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import getThumbnailsFromBricks from '@src/utils/getThumbnailsFromBricks'
import UspBrick from '@src/components/bricks/UspBrick'
import React from 'react'

const uspBrickResolve = (brick) => {
	const blockEditablesArray = findEditableByName(
		brick.editables,
		'contentblock',
	)
	var blockEditables = {}
	const arrayLength =
		blockEditablesArray && blockEditablesArray.objects
			? blockEditablesArray.objects.length
			: 0

	for (var i = 0; i < arrayLength; i++) {
		const editable = blockEditablesArray.objects[i]

		const editableIndexRegex = /contentblock:([0-9]+)/i
		const regexResult = editableIndexRegex.exec(editable.name)
		const editableIndex = parseInt(regexResult[1])

		const uspTitleRegex = /uspTitle/gi
		const isUspTitle = uspTitleRegex.test(editable.name)

		const uspTextRegex = /uspText/gi
		const isUspText = uspTextRegex.test(editable.name)

		const uspLinkRegex = /uspLink/gi
		const isUspLink = uspLinkRegex.test(editable.name)

		if (blockEditables['usp' + editableIndex] == null) {
			blockEditables['usp' + editableIndex] = {}
		}

		if (isUspTitle) {
			blockEditables['usp' + editableIndex] = {
				title: editable.value,
				...blockEditables['usp' + editableIndex],
			}
		} else if (isUspText) {
			blockEditables['usp' + editableIndex] = {
				text: editable.value,
				...blockEditables['usp' + editableIndex],
			}
		} else if (isUspLink) {
			blockEditables['usp' + editableIndex] = {
				link: editable.value,
				...blockEditables['usp' + editableIndex],
			}
		}
	}

	const margins = getMarginsFromBricks(brick)
	const images = getThumbnailsFromBricks(brick, 'logo')

	const data = {
		margins,
		images,
		imageAlt: findEditableByName(brick.editables, 'logo').title,
		bigGrid: findEditableByName(brick.editables, 'big-grid').value,
		blockEditables,

		buttonText: findEditableByName(brick.editables, 'button').text,
		buttonLink: findEditableByName(brick.editables, 'button').value,
	}

	return <UspBrick {...data} key={brick.key}></UspBrick>
}

export default uspBrickResolve
