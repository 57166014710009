import React from 'react'
import BEMHelper from 'react-bem-helper'
import Picture from '@src/components/Picture'

const Thumbnails = ({ data, bemClass, overlay }) => {
	const bem = new BEMHelper(bemClass)
	return data.images && data.imageAlt
		? data.images.map((image) => (
				<div
					key={data.imageAlt}
					{...(overlay ? bem('image', 'overlay') : bem('image'))}
				>
					<Picture {...image} alt={data.imageAlt} />
				</div>
		  ))
		: null
}

export default Thumbnails
