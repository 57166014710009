import './MembershipBrick.scss'

import React, { useEffect, useState, useRef } from 'react'
import BEMHelper from 'react-bem-helper'
import useTranslations from '@src/hooks/useTranslations'
import { useStaticQuery, graphql } from 'gatsby'
import { mobileSize, tabletSize } from '@src/javascript/variables'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { makeStyles } from '@material-ui/core/'
import BrickContainer from '@src/components/bricks/BrickContainer'
import Link from '@src/components/Link'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ReactComponent as Check } from '@src/assets/icons/check.svg'

const bem = new BEMHelper('membership-brick')

const useStyles = makeStyles({
	popover: {
		backgroundColor: '#fff !important',
	},
})

const MembershipBrick = ({ ...data }) => {
	const t = useTranslations()
	const [isTablet, setIsTablet] = useState(true)
	const [isMobile, setIsMobile] = useState(true)
	const [width, setWidth] = useState()
	const rowRef = useRef()
	const classes = useStyles()

	const { pimcore } = useStaticQuery(
		graphql`
			query VehicleTypeQuery {
				pimcore {
					getVehicleTypeListing {
						edges {
							node {
								Tier {
									... on Pimcore_fieldcollection_PriceTier {
										memberShipLevelActive
										membershipLevelName
										membershipButton {
											path
											text
										}
										numberOfUsers
										monthlyFee
										costPerHour
										costPerHourDiscount
										costPerDay
										costPerDayDiscount
										costPerWeekend
										costPerWeekendDiscount
										costPerWeek
										costPerWeekDiscount
										costPerMonth
										costPerMonthDiscount
										costPerKm
										costPerKmDiscount
										adminPortal
										advancedReport
										card
										community
										costCenters
										customOnboarding
										customPlaces
										environmentalReport
										extra1
										extra2
										extra3
										extra4
										extra5
										invoice
										journal
										logo
										premiumSupport
									}
								}
								B2BTier {
									... on Pimcore_fieldcollection_PriceTierBusiness {
										memberShipLevelActive
										membershipLevelName
										membershipButton {
											path
											text
										}
										numberOfUsers
										monthlyFee
										costPerHour
										costPerHourDiscount
										costPerDay
										costPerDayDiscount
										costPerWeekend
										costPerWeekendDiscount
										costPerWeek
										costPerWeekDiscount
										costPerMonth
										costPerMonthDiscount
										costPerKm
										costPerKmDiscount
										adminPortal
										advancedReport
										card
										community
										costCenters
										customOnboarding
										customPlaces
										environmentalReport
										extra1
										extra2
										extra3
										extra4
										extra5
										invoice
										journal
										logo
										premiumSupport
									}
								}
								Disclaimer
								B2BDisclaimer
								id
								Size
								Brand
								MainImage {
									fullpath(thumbnail: "membership_brick")
								}
							}
						}
					}
				}
			}
		`,
	)

	const vehicleTypeSmall = data.vehicletypes.vehicletypeSmall
		? data.vehicletypes.vehicletypeSmall.id
		: null
	const vehicleTypeMedium = data.vehicletypes.vehicletypeMedium
		? data.vehicletypes.vehicletypeMedium.id
		: null
	const vehicleTypeLarge = data.vehicletypes.vehicletypeLarge
		? data.vehicletypes.vehicletypeLarge.id
		: null
	const vehicleTypeVan = data.vehicletypes.vehicletypeVan
		? data.vehicletypes.vehicletypeVan.id
		: null
	const vehicleTypeLuxury = data.vehicletypes.vehicletypeLuxury
		? data.vehicletypes.vehicletypeLuxury.id
		: null
	const vehicleTypeHydrogen = data.vehicletypes.vehicletypeHydrogen
		? data.vehicletypes.vehicletypeHydrogen.id
		: null
	const vehicleTypeMediumSuv = data.vehicletypes.vehicletypeMediumSuv
		? data.vehicletypes.vehicletypeMediumSuv.id
		: null

	const findCar = (carId) => {
		const cars = pimcore.getVehicleTypeListing.edges.map(({ node }) => node)
		return cars.find((thisCar) => parseInt(thisCar.id) === carId)
	}

	const smallCar = [findCar(vehicleTypeSmall), 'small']
	const mediumCar = [findCar(vehicleTypeMedium), 'medium']
	const largeCar = [findCar(vehicleTypeLarge), 'large']
	const vanCar = [findCar(vehicleTypeVan), 'van']
	const luxuryCar = [findCar(vehicleTypeLuxury), 'luxury']
	const hydrogenCar = [findCar(vehicleTypeHydrogen), 'hydrogen']
	const mediumSuvCar = [findCar(vehicleTypeMediumSuv), 'mediumSuv']
	const cars = [
		smallCar,
		mediumCar,
		mediumSuvCar,
		largeCar,
		vanCar,
		luxuryCar,
		hydrogenCar,
	]

	let currentCar = null
	if (data.defaultCar) {
		let defaultCar = null
		switch (data.defaultCar) {
			case 'small':
				defaultCar = vehicleTypeSmall
				break
			case 'medium':
				defaultCar = vehicleTypeMedium
				break
			case 'large':
				defaultCar = vehicleTypeLarge
				break
			case 'van':
				defaultCar = vehicleTypeVan
				break
			case 'luxury':
				defaultCar = vehicleTypeLuxury
				break
			case 'hydrogen':
				defaultCar = vehicleTypeHydrogen
				break
			case 'mediumSuv':
				defaultCar = vehicleTypeMediumSuv
				break
			default:
				break
		}
		currentCar = findCar(defaultCar)
	}

	const [selectedCar, setselectedCar] = useState(currentCar)

	const levels =
		data.brickType === 'B2C'
			? selectedCar && selectedCar.Tier
			: data.brickType === 'B2B' && selectedCar && selectedCar.B2BTier
	const disclaimer =
		data.brickType === 'B2C'
			? selectedCar && selectedCar.Disclaimer
			: data.brickType === 'B2B' && selectedCar && selectedCar.B2BDisclaimer

	const handleChange = (event) => {
		currentCar = findCar(event.target.value)
		setselectedCar(currentCar)
	}

	useEffect(() => {
		const handleResize = () => {
			setIsTablet(
				typeof window === 'undefined' || window.innerWidth < tabletSize,
			)
			setIsMobile(
				typeof window === 'undefined' || window.innerWidth < mobileSize,
			)
		}
		window.addEventListener('resize', handleResize)
		setIsTablet(typeof window === 'undefined' || window.innerWidth < tabletSize)
		setIsMobile(typeof window === 'undefined' || window.innerWidth < mobileSize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		const handleResize = () => {
			setWidth(rowRef && rowRef.current && rowRef.current.offsetWidth)
		}

		window.addEventListener('resize', handleResize)
		setWidth(rowRef && rowRef.current && rowRef.current.offsetWidth)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const emptyGroup1 =
		levels &&
		levels.every((level) => (level.card || level.invoice) === null || false)

	const emptyGroup2 =
		levels &&
		levels.every(
			(level) =>
				(level.adminPortal ||
					level.advancedReport ||
					level.community ||
					level.costCenters ||
					level.costCenters ||
					level.customOnboarding ||
					level.customPlaces ||
					level.environmentalReport ||
					level.journal ||
					level.logo ||
					level.extra1 ||
					level.extra2 ||
					level.extra3 ||
					level.extra4 ||
					level.extra5) === (null || false || ''),
		)

	const Row = ({ title, description, cellContent }) => {
		cellContent = isMobile ? cellContent.slice(0, 3) : cellContent
		const emptyRow = cellContent.every(
			(item) => item.cell === '' || item.cell === false || item.cell === null,
		)

		return (
			!emptyRow && (
				<div {...bem('row')}>
					<div {...bem('cell')}>
						<ExpansionPanel
							TransitionProps={{
								style: { width: width },
							}}
						>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								<h5>{title}</h5>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>{description}</ExpansionPanelDetails>
						</ExpansionPanel>
					</div>
					{cellContent.map((item, i) => {
						return (
							<div
								{...bem(
									'cell',
									(item.active === false || item.active === null) &&
										'not-active',
								)}
								key={item.cell + i}
							>
								{item.cellDiscount ? (
									<div {...bem('discount-item')}>
										<p className='h6'>
											<span className='tiny'>
												{t('kinto.membership-brick.discount')}
											</span>{' '}
											{isMobile && <br />}
											{item.cellDiscount}
										</p>
									</div>
								) : item.cell === true || item.cell === '[true]' ? (
									<Check />
								) : (
									<p>{item.cell}</p>
								)}
							</div>
						)
					})}
				</div>
			)
		)
	}

	const CarPicker = () => {
		return (
			<div {...bem('car-picker')}>
				<FormControl variant='outlined'>
					<Select
						labelId='car-picker'
						value={selectedCar.id}
						onChange={handleChange}
						displayEmpty
						IconComponent={ExpandMoreIcon}
						inputProps={{ 'aria-label': 'Without label' }}
					>
						{cars.map((car) => {
							return (
								car[0] !== undefined &&
								car[0].Tier !== null && (
									<MenuItem value={parseInt(car[0].id)} key={car[0].id}>
										<p className='h5'>
											{t(`kinto.carattribute-${car[0].Size}`)}
										</p>
									</MenuItem>
								)
							)
						})}
					</Select>
				</FormControl>
			</div>
		)
	}

	return (
		<BrickContainer data={data}>
			{selectedCar && levels && (
				<div {...bem('')} id={'membership-brick'}>
					<Container>
						<div {...bem('container')}>
							{data.title && <h2 {...bem('title')}>{data.title}</h2>}
							{data.text && (
								<div {...bem('text')}>
									<p className='large'>{data.text}</p>
								</div>
							)}

							{isTablet && (
								<div {...bem('car-picker')}>
									{data.carText && <h3>{data.carText}</h3>} <CarPicker />
								</div>
							)}
							<div>
								<div {...bem('sticky')}>
									<div {...bem('row', 'top')}>
										{!isTablet && (
											<div {...bem('cell', 'top')}>
												{data.carText && <h3>{data.carText}</h3>}
											</div>
										)}
										{isMobile
											? levels.slice(0, 3).map((item) => {
													return (
														<div
															{...bem('cell', 'top')}
															key={item.membershipLevelName}
														>
															<h3>{item.membershipLevelName}</h3>
														</div>
													)
											  })
											: levels.map((item) => {
													return (
														<div
															{...bem('cell', 'top')}
															key={item.membershipLevelName}
														>
															<h3>{item.membershipLevelName}</h3>
														</div>
													)
											  })}
									</div>
									<div {...bem('row', 'top')}>
										{!isTablet && (
											<div {...bem('cell', 'top')}>
												<CarPicker />
											</div>
										)}
										{isMobile
											? levels.slice(0, 3).map((item, i) => {
													return (
														<div {...bem('cell', 'top')} key={'Price' + i}>
															{item.membershipButton &&
																item.membershipButton.text && (
																	<h6>{item.membershipButton.text}</h6>
																)}
														</div>
													)
											  })
											: levels.map((item, i) => {
													return (
														<div {...bem('cell', 'top')} key={'Price' + i}>
															{item.membershipButton &&
																item.membershipButton.text &&
																item.membershipButton.path && (
																	<Button
																		className={i !== 3 && 'ButtonOrange'}
																		aria-label={item.membershipButton.text}
																		variant={i === 3 ? 'outlined' : 'contained'}
																		color='primary'
																		fullWidth
																		to={item.membershipButton.path}
																		component={Link}
																		disabled={
																			item.memberShipLevelActive === false ||
																			item.memberShipLevelActive === null
																		}
																	>
																		{item.membershipButton.text}
																	</Button>
																)}
														</div>
													)
											  })}
									</div>
								</div>

								<div {...bem('group')} ref={rowRef}>
									<Row
										title={t('kinto.membership-brick.users.title')}
										description={t('kinto.membership-brick.users.description')}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.numberOfUsers,
												id: item.membershipLevelName,
											}
										})}
									/>

									<Row
										title={t('kinto.membership-brick.fee.title')}
										description={t('kinto.membership-brick.fee.description')}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.monthlyFee,
												id: item.membershipLevelName,
											}
										})}
									/>

									<Row
										title={t('kinto.membership-brick.hour.title')}
										description={t('kinto.membership-brick.hour.description')}
										cellContent={levels.map((item) => {
											return item.costPerHourDiscount !== ''
												? {
														active: item.memberShipLevelActive,
														cellDiscount: item.costPerHourDiscount,
														id: item.membershipLevelName,
												  }
												: {
														active: item.memberShipLevelActive,
														cell: item.costPerHour,
														id: item.membershipLevelName,
												  }
										})}
									/>

									<Row
										title={t('kinto.membership-brick.day.title')}
										description={t('kinto.membership-brick.day.description')}
										cellContent={levels.map((item) => {
											return item.costPerDayDiscount !== ''
												? {
														active: item.memberShipLevelActive,
														cellDiscount: item.costPerDayDiscount,
														id: item.membershipLevelName,
												  }
												: {
														active: item.memberShipLevelActive,
														cell: item.costPerDay,
														id: item.membershipLevelName,
												  }
										})}
									/>

									<Row
										title={t('kinto.membership-brick.weekend.title')}
										description={t(
											'kinto.membership-brick.weekend.description',
										)}
										cellContent={levels.map((item) => {
											return item.costPerWeekendDiscount !== ''
												? {
														active: item.memberShipLevelActive,
														cellDiscount: item.costPerWeekendDiscount,
														id: item.membershipLevelName,
												  }
												: {
														active: item.memberShipLevelActive,
														cell: item.costPerWeekend,
														id: item.membershipLevelName,
												  }
										})}
									/>

									<Row
										title={t('kinto.membership-brick.week.title')}
										description={t('kinto.membership-brick.week.description')}
										cellContent={levels.map((item) => {
											return item.costPerWeekDiscount !== ''
												? {
														active: item.memberShipLevelActive,
														cellDiscount: item.costPerWeekDiscount,
														id: item.membershipLevelName,
												  }
												: {
														active: item.memberShipLevelActive,
														cell: item.costPerWeek,
														id: item.membershipLevelName,
												  }
										})}
									/>

									<Row
										title={t('kinto.membership-brick.month.title')}
										description={t('kinto.membership-brick.month.description')}
										cellContent={levels.map((item) => {
											return item.costPerMonthDiscount !== ''
												? {
														active: item.memberShipLevelActive,
														cellDiscount: item.costPerMonthDiscount,
														id: item.membershipLevelName,
												  }
												: {
														active: item.memberShipLevelActive,
														cell: item.costPerMonth,
														id: item.membershipLevelName,
												  }
										})}
									/>

									<Row
										title={t('kinto.membership-brick.kilometers.title')}
										description={t(
											'kinto.membership-brick.kilometers.description',
										)}
										cellContent={levels.map((item) => {
											return item.costPerKmDiscount !== ''
												? {
														active: item.memberShipLevelActive,
														cellDiscount: item.costPerKmDiscount,
														id: item.membershipLevelName,
												  }
												: {
														active: item.memberShipLevelActive,
														cell: item.costPerKm,
														id: item.membershipLevelName,
												  }
										})}
									/>
								</div>
								<div {...bem('group')}>
									{!emptyGroup1 && (
										<h4>{t('kinto.membership-brick.title.payment')}</h4>
									)}
									<Row
										title={t('kinto.membership-brick.card.title')}
										description={t('kinto.membership-brick.card.description')}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.card,
												id: item.membershipLevelName,
											}
										})}
									/>
									<Row
										title={t('kinto.membership-brick.invoice.title')}
										description={t(
											'kinto.membership-brick.invoice.description',
										)}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.invoice,
												id: item.membershipLevelName,
											}
										})}
									/>
								</div>
								<div {...bem('group')}>
									{!emptyGroup2 && (
										<h4>{t('kinto.membership-brick.title.extra')}</h4>
									)}
									<Row
										title={t('kinto.membership-brick.journal.title')}
										description={t(
											'kinto.membership-brick.journal.description',
										)}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.journal,
												id: item.membershipLevelName,
											}
										})}
									/>
									<Row
										title={t('kinto.membership-brick.logo.title')}
										description={t('kinto.membership-brick.logo.description')}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.logo,
												id: item.membershipLevelName,
											}
										})}
									/>
									<Row
										title={t('kinto.membership-brick.adminPortal.title')}
										description={t(
											'kinto.membership-brick.adminPortal.description',
										)}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.adminPortal,
												id: item.membershipLevelName,
											}
										})}
									/>
									<Row
										title={t(
											'kinto.membership-brick.environmentalReport.title',
										)}
										description={t(
											'kinto.membership-brick.environmentalReport.description',
										)}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.environmentalReport,
												id: item.membershipLevelName,
											}
										})}
									/>
									<Row
										title={t('kinto.membership-brick.advancedReport.title')}
										description={t(
											'kinto.membership-brick.advancedReport.description',
										)}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.advancedReport,
												id: item.membershipLevelName,
											}
										})}
									/>
									<Row
										title={t('kinto.membership-brick.costCenters.title')}
										description={t(
											'kinto.membership-brick.costCenters.description',
										)}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.costCenters,
												id: item.membershipLevelName,
											}
										})}
									/>
									<Row
										title={t('kinto.membership-brick.community.title')}
										description={t(
											'kinto.membership-brick.community.description',
										)}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.community,
												id: item.membershipLevelName,
											}
										})}
									/>
									<Row
										title={t('kinto.membership-brick.premiumSupport.title')}
										description={t(
											'kinto.membership-brick.premiumSupport.description',
										)}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.premiumSupport,
												id: item.membershipLevelName,
											}
										})}
									/>
									<Row
										title={t('kinto.membership-brick.customPlaces.title')}
										description={t(
											'kinto.membership-brick.customPlaces.description',
										)}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.customPlaces,
												id: item.membershipLevelName,
											}
										})}
									/>
									<Row
										title={t('kinto.membership-brick.customOnboarding.title')}
										description={t(
											'kinto.membership-brick.customOnboarding.description',
										)}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.customOnboarding,
												id: item.membershipLevelName,
											}
										})}
									/>
									<Row
										title={t('kinto.membership-brick.extra1.title')}
										description={t('kinto.membership-brick.extra1.description')}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.extra1,
												id: item.membershipLevelName,
											}
										})}
									/>
									<Row
										title={t('kinto.membership-brick.extra2.title')}
										description={t('kinto.membership-brick.extra2.description')}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.extra2,
												id: item.membershipLevelName,
											}
										})}
									/>
									<Row
										title={t('kinto.membership-brick.extra3.title')}
										description={t('kinto.membership-brick.extra3.description')}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.extra3,
												id: item.membershipLevelName,
											}
										})}
									/>
									<Row
										title={t('kinto.membership-brick.extra4.title')}
										description={t('kinto.membership-brick.extra4.description')}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.extra4,
												id: item.membershipLevelName,
											}
										})}
									/>
									<Row
										title={t('kinto.membership-brick.extra5.title')}
										description={t('kinto.membership-brick.extra5.description')}
										cellContent={levels.map((item) => {
											return {
												active: item.memberShipLevelActive,
												cell: item.extra5,
												id: item.membershipLevelName,
											}
										})}
									/>
								</div>
								{isMobile && (
									<div {...bem('sticky-bottom')}>
										<FormControl variant='outlined'>
											<InputLabel id='level-picker'>
												<h6>{t('kinto.membership-brick.pick-level')}</h6>
											</InputLabel>
											<Select
												labelId='level-picker'
												IconComponent={ExpandMoreIcon}
												MenuProps={{
													getContentAnchorEl: null,
													anchorOrigin: {
														vertical: 'top',
														horizontal: 'center',
													},
													transformOrigin: {
														vertical: 'bottom',
														horizontal: 'center',
													},
												}}
											>
												{levels.map((level) => {
													return (
														level.membershipButton &&
														level.membershipButton.path && (
															<MenuItem
																key={level.membershipButton.path}
																className={classes.popover}
																disabled={!level.memberShipLevelActive}
															>
																{level.memberShipLevelActive ? (
																	<Link
																		to={level.membershipButton.path}
																		{...bem('level-active')}
																	>
																		<p className='h5'>
																			{level.membershipLevelName}
																		</p>
																	</Link>
																) : (
																	<span
																		{...bem('level-not-active')}
																		key={level.membershipButton.path}
																	>
																		<p className='h5'>
																			{level.membershipLevelName}
																		</p>
																	</span>
																)}
															</MenuItem>
														)
													)
												})}
											</Select>
										</FormControl>
									</div>
								)}
								{disclaimer !== null && (
									<div
										{...bem('extra-text')}
										dangerouslySetInnerHTML={{
											__html: disclaimer,
										}}
									></div>
								)}
							</div>
						</div>
					</Container>
				</div>
			)}
		</BrickContainer>
	)
}

export default MembershipBrick
