import './CarsBrick.scss'

import React from 'react'
import BEMHelper from 'react-bem-helper'
import Slider from 'react-slick'
import useSetting from '@src/hooks/useSetting'
import useTranslations from '@src/hooks/useTranslations'
import { Container, Button } from '@material-ui/core'
import NextIcon from '@material-ui/icons/ArrowForwardIosRounded'
import BackIcon from '@material-ui/icons/ArrowBackIosRounded'
import Link from '@src/components/Link'
import BrickContainer from '@src/components/bricks/BrickContainer'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const bem = new BEMHelper('cars-brick')

const Arrow = ({ currentSlide, slideCount, children, ...props }) => (
	<div {...props}>{children}</div>
)

const CarsBrick = ({ ...data }) => {
	const t = useTranslations()
	const getSetting = useSetting()
	const cdnHost = getSetting('CDNHost', '')
	var settings = {
		slidesToScroll: 1,
		variableWidth: true,
		focusOnSelect: true,
		infinite: false,
		nextArrow: (
			<Arrow>
				<NextIcon />
			</Arrow>
		),
		prevArrow: (
			<Arrow>
				<BackIcon />
			</Arrow>
		),
	}

	return (
		<BrickContainer data={data}>
			<div {...bem('')} id={'cars-brick'}>
				<Container>
					<div {...bem('container')}>
						<div {...bem('container-text')}>
							{data.brickTitle && (
								<div {...bem('title')}>
									<h2 className='h1'>{data.brickTitle}</h2>
								</div>
							)}
							{data.brickText && <p {...bem('text')}>{data.brickText}</p>}
						</div>
						<div {...bem('container-slider')}>
							<Slider {...settings}>
								{Object.values(data.blockEditables).map((item) => {
									if (item.title || item.image) {
										return (
											<div {...bem('car')} key={item.title}>
												<Link
													to={item.notActive ? null : item.linkPath}
													{...bem('car-link')}
												>
													{item.image && item.imageAlt && (
														<img
															src={`${cdnHost}${item.image}`}
															alt={item.imageAlt}
															className={
																item.notActive ? 'not-active' : 'active'
															}
														/>
													)}

													<div {...bem('car-name')}>
														<h3 className='h4'>{item.title}</h3>
													</div>
												</Link>
												<div {...bem('car-bottom')}>
													{item.notActive ? (
														<p {...bem('car-text')}>
															{item.linkText
																? item.linkText
																: t('kinto.general.coming-soon')}
														</p>
													) : (
														<Button
															color='primary'
															variant='outlined'
															to={item.linkPath}
															component={Link}
														>
															{item.linkText
																? item.linkText
																: t('kinto.general.read-more')}
														</Button>
													)}
												</div>
											</div>
										)
									}
									return null
								})}
							</Slider>
						</div>
					</div>
				</Container>
			</div>
		</BrickContainer>
	)
}

export default CarsBrick
