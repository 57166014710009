import './ImageAndTextBrick.scss'
import React from 'react'
import BEMHelper from 'react-bem-helper'
import { Container, Button } from '@material-ui/core'
import BrickContainer from '@src/components/bricks/BrickContainer'
import Thumbnails from '@src/components/Thumbnails'
import Link from '@src/components/Link'

const bem = new BEMHelper('image-and-text-brick')

const ImageAndTextBrick = ({ ...data }) => {
	return (
		<BrickContainer data={data}>
			<div {...bem('')} id={'image-and-text-brick'}>
				<Container>
					<div {...bem('container')}>
						<Thumbnails data={data} bemClass={'image-and-text-brick'} />
						<div {...bem('info-container')}>
							<div {...bem('text-container')}>
								{data.title && (
									<div {...bem('title')}>
										<h2 className='h1'>{data.title}</h2>
									</div>
								)}
								{data.text && <p {...bem('text')}>{data.text}</p>}
							</div>
							{data.link.target && data.link.linkText && (
								<div {...bem('button')}>
									<Button
										fullWidth
										color='primary'
										variant='contained'
										size='large'
										to={data.link.target}
										component={Link}
									>
										{data.link.linkText}
									</Button>
								</div>
							)}
						</div>
					</div>
				</Container>
			</div>
		</BrickContainer>
	)
}

export default ImageAndTextBrick
