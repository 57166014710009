import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import ColumnCheckBrick from '@src/components/bricks/ColumnCheckBrick'
import React from 'react'

const columnCheckBrickResolve = (brick) => {
	const blockEditablesArray = findEditableByName(
		brick.editables,
		'contentblock',
	)
	var blockEditables = {}
	const arrayLength =
		blockEditablesArray && blockEditablesArray.objects
			? blockEditablesArray.objects.length
			: 0

	for (var i = 0; i < arrayLength; i++) {
		const editable = blockEditablesArray.objects[i]

		const editableIndexRegex = /contentblock:([0-9]+)/i
		const regexResult = editableIndexRegex.exec(editable.name)
		const editableIndex = parseInt(regexResult[1])

		const checkTextRegex = /checkText/gi
		const isCheckText = checkTextRegex.test(editable.name)

		if (blockEditables['columnCheck' + editableIndex] == null) {
			blockEditables['columnCheck' + editableIndex] = {}
		}

		if (isCheckText) {
			blockEditables['columnCheck' + editableIndex] = Object.assign(
				{ checkText: editable.value },
				blockEditables['columnCheck' + editableIndex],
			)
		}
	}

	const margins = getMarginsFromBricks(brick)

	const data = {
		margins,
		bigGrid: findEditableByName(brick.editables, 'big-grid').value,
		title: findEditableByName(brick.editables, 'title').value,
		blockEditables,
	}

	return <ColumnCheckBrick {...data} key={brick.key}></ColumnCheckBrick>
}

export default columnCheckBrickResolve
