import { useState, useEffect } from 'react'

const usePosition = () => {
	const [position, setPosition] = useState({})
	const [error, setError] = useState(null)

	const onPosition = ({ coords }) => {
		setPosition({
			latitude: coords.latitude,
			longitude: coords.longitude,
		})
	}

	const onError = (error) => {
		setError(error.message)
	}

	useEffect(() => {
		if (typeof navigator === 'undefined' || !navigator.geolocation) {
			return { error: 'Geo Positining is not supported in your browser' }
		}
		navigator.geolocation.getCurrentPosition(onPosition, onError)
	}, [])

	return { ...position, error }
}

export default usePosition
