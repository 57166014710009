import './LeadHeroImageBrick.scss'
import '../CustomButtons.scss'
import React, { useState } from 'react'
import BEMHelper from 'react-bem-helper'
import { get } from 'lodash'
import Container from '@material-ui/core/Container'
import { Formik, Form } from 'formik'
import { useConnect } from 'redux-bundler-hook'
import { graphql, useStaticQuery } from 'gatsby'
import Button from '@material-ui/core/Button'
import BrickContainer from '@src/components/bricks/BrickContainer'
import { TextField } from '@material-ui/core/'
import useTranslations from '@src/hooks/useTranslations'
import validator from '@src/form/validations'
import Consent from '@src/components/Consent'
import { azureApiGatewayPost } from '@src/api/azureApiGateway/client'
import ErrorModal from '@src/components/ErrorModal'
import Thumbnails from '@src/components/Thumbnails'

const bem = new BEMHelper('lead-hero-image-brick')

const query = graphql`
	query getSnippetsHero {
		allSnippet {
			edges {
				node {
					editables {
						name
						type
						value
					}
					name
				}
			}
		}
	}
`

export default function LeadHeroImageBrick({ ...data }) {
	const t = useTranslations()

	const [errorMessage, showErrorMessage] = useState(null)

	const { customerInfo } = useConnect('selectCustomerInfo')
	const { allSnippet } = useStaticQuery(query)
	const privacyPlocySnippet = allSnippet.edges.find(
		({ node }) => node.name === 'privacyPolicy',
	)
	function getSnippetValue(valueName) {
		const field = privacyPlocySnippet.node.editables.find(
			(editable) => editable.name === valueName,
		)
		return field && field.value
	}

	return (
		<BrickContainer data={data}>
			<div {...bem('')} id={'lead-hero-image-brick'}>
				<Thumbnails data={data} bemClass={'lead-hero-image-brick'} />

				<div {...bem('container')}>
					<Container>
						<div {...bem('content-container')}>
							{data.title && (
								<div {...bem('title')}>
									{data.titleTag === 'H1' ? (
										<h1 className='display'>{data.title}</h1>
									) : data.titleTag === 'H2' ? (
										<h2 className='display'>{data.title}</h2>
									) : (
										data.titleTag === 'H3' && (
											<h3 className='display'>{data.title}</h3>
										)
									)}
								</div>
							)}
							{data.text && (
								<div {...bem('text')}>
									<p className='intro'>{data.text}</p>
								</div>
							)}
							{data.secondTitle && (
								<div {...bem('second-title')}>
									{data.secondTitleTag === 'H2' ? (
										<h2 className='h3'>{data.secondTitle}</h2>
									) : data.secondTitleTag === 'H3' ? (
										<h3 className='h3'>{data.secondTitle}</h3>
									) : (
										data.secondTitleTag === 'H4' && (
											<h4 className='h3'>{data.secondTitle}</h4>
										)
									)}
								</div>
							)}

							<Formik
								initialValues={{
									email:
										customerInfo && customerInfo.email
											? customerInfo.email
											: '',
								}}
								validationSchema={validator({
									email: {
										validatorKey: 'email',
										required: true,
									},
								})}
								enableReinitialize={true}
							>
								{(props) => {
									const {
										values,
										isValid,
										handleChange,
										handleBlur,
										errors,
										touched,
									} = props
									const disabled = !isValid || values.email === ''

									return (
										<Form>
											<div {...bem('email-row')}>
												<TextField
													value={values.email}
													label={t('kinto.form.email')}
													variant='filled'
													name='email'
													required
													onChange={handleChange}
													onBlur={handleBlur}
													error={errors.email && touched.email}
													helperText={
														errors.email && touched.email && errors.email
													}
													InputProps={{
														disableUnderline: true,
													}}
												/>
												<Button
													className='ButtonOrange'
													aria-label={data.buttonText}
													variant='contained'
													color='primary'
													disabled={disabled}
													onClick={async () => {
														try {
															const response = await azureApiGatewayPost(
																'crm/lead',
																{
																	email: values.email,
																	page: window.location.pathname,
																	type: data.area,
																},
															)
															const leadId = response.data.lead_id
															window.location.href = `${data.formUrl}?id=${leadId}&email=${values.email}`
														} catch (error) {
															if (get(error, 'response.data.messages')) {
																showErrorMessage(error.response.data.messages)
															}
														}
													}}
												>
													{data.buttonText}
												</Button>
											</div>
										</Form>
									)
								}}
							</Formik>
							<p {...bem('privacy')}>
								{data.privacyText}{' '}
								<Consent
									header={getSnippetValue('header')}
									linkText={getSnippetValue('linkText')}
									content={getSnippetValue('content')}
								></Consent>
							</p>
							{errorMessage && (
								<ErrorModal onClose={() => showErrorMessage(null)}>
									{errorMessage}
								</ErrorModal>
							)}
						</div>
					</Container>
				</div>
			</div>
		</BrickContainer>
	)
}
