import React, { useEffect, useRef, useState } from 'react'
import { find } from 'lodash'
import CircularProgress from '@material-ui/core/CircularProgress'
import pinSearch from '@src/assets/icons/pinSearch.svg'
import pinGreen from '@src/assets/icons/pinGreen.svg'
import pinRed from '@src/assets/icons/pinRed.svg'
import pinBlue from '@src/assets/icons/pinBlue.svg'
import usePosition from '@src/hooks/usePosition'
import { navigate } from 'gatsby'
import { useConnect } from 'redux-bundler-hook'
import { localizedUrl } from '@src/utils/navigate'

import { getStationsClosestToLocation } from '@src/utils/calculations'

import events, { EVENT_CENTER_MAP_ON_POSITION } from '@src/utils/events'

const FALLBACK_LAT =
	parseFloat(process.env.GATSBY_FALLBACK_COORDINATE_LAT) || 59.330783
const FALLBACK_LNG =
	parseFloat(process.env.GATSBY_FALLBACK_COORDINATE_LNG) || 18.057943

const Map = ({
	brick,
	showNearByStations = true,
	selectedLocation,
	selectedStation,
	previousSelectedStation,
	lat = FALLBACK_LAT,
	lng = FALLBACK_LNG,
	width = '100%',
	height = 1000,
	stations = [],
	filteredStations,
	chosenFilters,
	setVisibleStations,
	mapMoved,
	isMobile,
	onMarkerClicked = () => {},
	disabled,
	zoomControl = false,
	mapTypeControl = false,
	scaleControl = false,
	fullscreenControl = false,
	loaded,
	loadError,
	brickZoom,
}) => {
	const [initCompleted, setInitCompleted] = useState(false)
	const position = usePosition()
	let googleMap = useRef(null)
	let googleMapsCurrentPositionMarker = useRef(null)
	const { doSelectStation, language, service } = useConnect(
		'doSelectStation',
		'selectLanguage',
		'selectService',
	)

	const [mapStations, setMapStations] = useState([])
	const [stationMarkers] = useState({})
	const [selectedMarkerLocation, setSelectedMarkerLocation] = useState(null)
	const [mapPadding, setMapPadding] = useState(isMobile ? 25 : 125)
	const [prevZoom, setPrevZoom] = useState()
	const [prevCenter, setPrevCenter] = useState()

	const zoom = 15

	useEffect(() => setMapPadding(isMobile ? 25 : 125), [isMobile])

	const getOrCreateBound = (newBound = false) => {
		if (!loaded || !googleMap.current) {
			console.log('Script not loaded or map not set while fetching bound')
			return null
		}
		let bound = googleMap.current.getBounds()
		if (!bound || newBound) {
			bound = new window.google.maps.LatLngBounds()
		}
		if (bound && bound.isEmpty()) {
			if (selectedStation) {
				bound.extend(
					new window.google.maps.LatLng(
						selectedStation.location.lat,
						selectedStation.location.lng,
					),
				)
			} else if (selectedLocation) {
				bound.extend(
					new window.google.maps.LatLng(
						selectedLocation.geoData.geometry.location.lat(),
						selectedLocation.geoData.geometry.location.lng(),
					),
				)
			} else {
				bound.extend(
					new window.google.maps.LatLng(
						lat, //position && position.latitude ? position.latitude : lat,
						lng, // position && position.longitude ? position.longitude : lng,
					),
				)
			}
		}
		return bound
	}

	const filterVisibleStations = () => {
		if (disabled) {
			return
		}
		const bound = getOrCreateBound()
		const visibleStations = mapStations.filter(({ id }) =>
			bound.contains(stationMarkers[id].latLng),
		)
		if (!brick) {
			setVisibleStations(visibleStations)
		}
	}

	useEffect(() => {
		if (
			stations &&
			stations.length > 0 &&
			stations.length !== mapStations.length
		)
			setMapStations(stations)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stations, setMapStations])

	const centerMapOnPosition = (pos = null) => {
		if (!pos) {
			return
		}
		googleMap.current.setCenter({
			lat: pos.latitude,
			lng: pos.longitude,
		})
	}

	useEffect(() => {
		events.on(EVENT_CENTER_MAP_ON_POSITION, centerMapOnPosition)
		return () =>
			events.removeListener(EVENT_CENTER_MAP_ON_POSITION, centerMapOnPosition)
	}, [])

	useEffect(() => {
		if (loaded && !loadError && !initCompleted) {
			googleMap.current = new window.google.maps.Map(
				window ? document.getElementById('google-map') : null,
				{
					zoom,
					center: {
						lat, //: position && position.latitude ? position.latitude : lat,
						lng, //: position && position.longitude ? position.longitude : lng,
					},
					disableDefaultUI: true,
					gestureHandling: brick ? 'none' : 'auto',
					zoomControl: zoomControl,
					zoomControlOptions: zoomControl
						? {
								position: window.google.maps.ControlPosition.RIGHT_TOP,
						  }
						: null,
					mapTypeControl: mapTypeControl,
					mapTypeControlOptions: mapTypeControl
						? {
								style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
						  }
						: null,
					scaleControl: !!scaleControl,
					fullscreenControl: fullscreenControl,
					fullscreenControlOptions: fullscreenControl
						? {
								position: window.google.maps.ControlPosition.RIGHT_TOP,
						  }
						: null,
				},
			)
			getOrCreateBound()
			setInitCompleted(true)
		}

		if (loaded && mapStations.length > 0) {
			window.google.maps.event.addListener(
				googleMap.current,
				'zoom_changed',
				filterVisibleStations,
			)
			window.google.maps.event.addListener(
				googleMap.current,
				'drag',
				filterVisibleStations,
			)
		}

		if (loaded && !disabled && showNearByStations) {
			addStationsToMap(googleMap.current, mapStations)
		}

		if (loaded && position && position.latitude && position.longitude) {
			googleMapsCurrentPositionMarker.current = new window.google.maps.Marker({
				map: googleMap.current,
			})
			googleMapsCurrentPositionMarker.current.setPosition({
				lat: position.latitude,
				lng: position.longitude,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loaded, mapStations, lat, lng, setInitCompleted])

	function zoomToStation(station) {
		const origin = new window.google.maps.LatLng(
			station.location.lat,
			station.location.lng,
		)
		events.emit(EVENT_CENTER_MAP_ON_POSITION, {
			latitude: station.location.lat,
			longitude: station.location.lng,
		})
		googleMap.current.setZoom(15)
		googleMap.current.setCenter(origin)
	}

	useEffect(() => {
		if (loaded && initCompleted) {
			const bound = getOrCreateBound(!!selectedLocation)
			if (selectedStation) {
				setPrevZoom(googleMap.current.getZoom())
				setPrevCenter(googleMap.current.getCenter())
				zoomToStation(selectedStation)
			} else if (selectedLocation) {
				const selectedLocationOrigin = new window.google.maps.LatLng(
					selectedLocation.geoData.geometry.location.lat(),
					selectedLocation.geoData.geometry.location.lng(),
				)
				if (!brick) {
					const marker = createItemAndMarker(
						selectedLocationOrigin,
						googleMap.current,
						pinSearch,
						47,
					)
					if (selectedMarkerLocation) {
						selectedMarkerLocation.marker.setMap(null)
					}
					bound.extend(marker.latLng)
					setSelectedMarkerLocation(marker)
					getStationsClosestToLocation(
						{
							lat: selectedLocation.geoData.geometry.location.lat(),
							lng: selectedLocation.geoData.geometry.location.lng(),
						},
						mapStations,
						3,
					).forEach(({ stationId }) => {
						if (stationMarkers[stationId]) {
							bound.extend(stationMarkers[stationId].latLng)
						}
					})
				}

				googleMap.current.fitBounds(bound, mapPadding)
				if (brick) {
					googleMap.current.setZoom(parseInt(brickZoom))
				}
				if (!brick) {
					googleMap.current.addListener('zoom_changed', () => {
						mapMoved()
					})
					googleMap.current.addListener('drag', () => {
						mapMoved()
					})
				}
			} else {
				const origin = new window.google.maps.LatLng(
					lat, // position && position.latitude && false ? position.latitude : lat,
					lng, // position && position.longitude && false ? position.longitude : lng,
				)
				bound.extend(origin)
				if (!disabled && showNearByStations) {
					/**
					 * This code takes the closest station and adds it to the bound, should be at least 3....
					 const closestStation = getClosestStation(origin, stations)
					 if (closestStation) {
						 let marker = stationMarkers[closestStation.id]
						 if (!marker) {
							 const markerLatLng = new window.google.maps.LatLng(
								 closestStation.location.lat,
								 closestStation.location.lng,
								 )
								 marker = createItemAndMarker(
									 markerLatLng,
									 googleMap.current,
									 locationOnIcon,
									 closestStation,
									 true,
									 )
									 stationMarkers[closestStation.id] = marker
									}
									bound.extend(stationMarkers[closestStation.id].latLng)
								}
								*/

					/**
					 * Adding all stations to bound while only usage in Stockholm, above code needs to be used with more then one station when implementing sountry wide.
					 */
					for (let key in stationMarkers) {
						bound.extend(stationMarkers[key].latLng)
					}
					googleMap.current.fitBounds(bound, mapPadding)

					if (!(prevCenter === undefined || prevZoom === undefined)) {
						googleMap.current.setZoom(prevZoom)
						googleMap.current.setCenter(prevCenter)
					}
					filterVisibleStations()
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loaded, initCompleted, selectedStation, selectedLocation, mapStations])

	function addStationsToMap(map, stations) {
		stations.map((station) => {
			const markerLatLng = new window.google.maps.LatLng(
				station.location.lat,
				station.location.lng,
			)
			return createItemAndMarker(markerLatLng, map, pinBlue, 0, station, true)
		})
	}

	function createItemAndMarker(
		latLng,
		gMap,
		icon,
		size,
		item,
		addToMarkers = false,
	) {
		var markerIcon = {
			url: icon,
			scaledSize: new window.google.maps.Size(size, size),
		}
		const marker = new window.google.maps.Marker({
			position: latLng,
			map: gMap,
			icon: markerIcon,
		})
		marker.addListener('click', function() {
			if (!brick) {
				events.emit(EVENT_CENTER_MAP_ON_POSITION, {
					latitude: marker.position.lat,
					longitude: marker.position.lng,
				})
				onMarkerClicked(item)
			} else {
				doSelectStation(item)
				navigate(localizedUrl('/boka-bil', language, service))
			}
		})
		if (addToMarkers) {
			stationMarkers[item.id] = { marker, latLng }
		}
		return { marker, latLng }
	}

	useEffect(() => {
		Object.entries(stationMarkers).forEach(([key, value]) => {
			if (chosenFilters.length > 0 && !brick) {
				const filteredMarkers = find(filteredStations, { id: key })
				if (filteredMarkers === undefined) {
					value.marker.setIcon({
						url: pinRed,
						scaledSize: new window.google.maps.Size(47, 47),
					})
				} else {
					value.marker.setIcon({
						url: pinGreen,
						scaledSize: new window.google.maps.Size(47, 47),
					})
				}
			} else {
				value.marker.setIcon({
					url: pinBlue,
					scaledSize: new window.google.maps.Size(47, 47),
				})
			}
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chosenFilters, filteredStations])

	return (
		<>
			{!loaded && <CircularProgress />}
			{loadError && <div>Error loading</div>}
			<div id='google-map' style={{ width, height, margin: '0 auto' }} />
		</>
	)
}

export default Map
