import { findEditableByName } from '@src/utils/BrickResolver'

export default function getMarginsFromBricks(brick) {
	const marginTop = findEditableByName(brick.editables, 'margin-top').value
	const marginBottom = findEditableByName(brick.editables, 'margin-bottom')
		.value
	const marginTopBottom = findEditableByName(
		brick.editables,
		'margin-top-bottom',
	).value

	return {
		marginTop: marginTop,
		marginBottom: marginBottom,
		marginTopBottom: marginTopBottom,
	}
}
