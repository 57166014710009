import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import MapBrick from '@src/components/bricks/MapBrick'
import React from 'react'

const mapBrickResolve = (brick) => {
	const margins = getMarginsFromBricks(brick)

	const data = {
		margins,
		zoom: findEditableByName(brick.editables, 'zoom').value,
		zoomTablet: findEditableByName(brick.editables, 'zoomTablet').value,
		zoomMobile: findEditableByName(brick.editables, 'zoomMobile').value,
		area: findEditableByName(brick.editables, 'area').value,
	}

	return <MapBrick {...data} key={brick.key}></MapBrick>
}

export default mapBrickResolve
