import './CarBrick.scss'

import React, { useState, useRef, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useConnect } from 'redux-bundler-hook'
import BEMHelper from 'react-bem-helper'
import Slider from 'react-slick'
import useTranslations from '@src/hooks/useTranslations'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import NextIcon from '@material-ui/icons/ArrowForwardIosRounded'
import BackIcon from '@material-ui/icons/ArrowBackIosRounded'
import ArrowAccordion from '@material-ui/icons/KeyboardArrowDown'
import CarAttribute from '@src/components/booking/CarAttribute'
import BrickContainer from '@src/components/bricks/BrickContainer'
import Picture from '@src/components/Picture'
import getThumbnailsFromCar from '@src/utils/getThumbnailsFromCar'
import Link from '@src/components/Link'
import { navigate } from 'gatsby'
import { localizedUrl } from '@src/utils/navigate'

const bem = new BEMHelper('car-brick')

const Arrow = ({ currentSlide, slideCount, children, ...props }) => (
	<div {...props}>{children}</div>
)

const CarBrick = ({ ...data }) => {
	const { pimcore } = useStaticQuery(
		graphql`
			query VehicleTypeQueryCarBrick {
				pimcore {
					getVehicleTypeListing {
						edges {
							node {
								id
								Model
								Brand
								NoOfSeats
								AddtionalInformation
								Size
								Towbar
								Specifications
								Description
								VehicleType
								RidecellID
								Images {
									image {
										filename
										fullpath(thumbnail: "car_brick")
										srcset(thumbnail: "car_brick") {
											descriptor
											url
											resolutions {
												url
												resolution
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`,
	)

	const { doSetBookingSelectedCar, language, service } = useConnect(
		'doSetBookingSelectedCar',
		'selectLanguage',
		'selectService',
	)

	const useElementHeight = (thisRef) => {
		const getHeight = () => thisRef.current.offsetHeight
		const [height, setHeight] = useState(0)

		useEffect(() => {
			const handleResize = () => {
				setHeight(getHeight())
			}

			// Waiting for images to load
			setTimeout(() => {
				if (thisRef.current) {
					setHeight(getHeight())
				}
			}, 500)

			window.addEventListener('resize', handleResize)

			return () => {
				window.removeEventListener('resize', handleResize)
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [thisRef])

		return height
	}

	const t = useTranslations()
	const vehicleTypeId = data.vehicletype ? data.vehicletype.id : null
	let car = null
	if (vehicleTypeId) {
		const cars = pimcore.getVehicleTypeListing.edges.map(({ node }) => node)
		car = cars.find((car) => parseInt(car.id) === vehicleTypeId)
	}
	let images = null
	if (car) {
		images = getThumbnailsFromCar(car)
	}
	const textRef = useRef()
	const carouselRef = useRef()
	const heightText = useElementHeight(textRef)
	const heightCarousel = useElementHeight(carouselRef)
	const blueContainerHeight = heightCarousel / 2 + heightText

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: (
			<Arrow>
				<NextIcon />
			</Arrow>
		),
		prevArrow: (
			<Arrow>
				<BackIcon />
			</Arrow>
		),
	}

	return (
		<BrickContainer data={data}>
			{car && (
				<div {...bem('')} id={'car-brick'}>
					<div
						{...bem('blue-container')}
						style={{ height: blueContainerHeight }}
					></div>
					<Container>
						<div {...bem('text')} ref={textRef}>
							<h1 className='display'>{car.Brand}</h1>
							<h2 className='h3'>{car.Model}</h2>
						</div>
						<div {...bem('carousel')} ref={carouselRef}>
							{car.Images && (
								<Slider {...settings}>
									{images.map((image) => {
										return (
											<Picture
												key={car.Brand}
												{...image[0]}
												alt={data.imageAlt}
											/>
										)
									})}
								</Slider>
							)}
						</div>
						<div {...bem('description')}>
							<p className='large'>{car.AddtionalInformation}</p>
						</div>
						<div {...bem('container-content')}>
							<div {...bem('container-left')}>
								{data.link.target && data.link.text && (
									<Button
										className='ButtonOrange'
										color='primary'
										variant='contained'
										fullWidth
										component={Link}
										to={data.link.target !== 'selectCar' && data.link.target}
										{...(data.link.target === 'selectCar' && {
											onClick: () => {
												doSetBookingSelectedCar(car)
												navigate(localizedUrl('/boka-bil', language, service))
											},
										})}
									>
										{data.link.text}
									</Button>
								)}
								<div {...bem('attributes')}>
									<CarAttribute type={car.Size} />
									{car.TowBar && <CarAttribute type={'towbar'} />}
									<CarAttribute type={'seats'} numberOfSeats={car.NoOfSeats} />
								</div>
							</div>
							<div {...bem('container-right')}>
								{car.Description && (
									<ExpansionPanel square>
										<ExpansionPanelSummary expandIcon={<ArrowAccordion />}>
											<p {...bem('accordion-heading')}>
												{t('kinto.car-brick.description')}
											</p>
										</ExpansionPanelSummary>
										<ExpansionPanelDetails
											dangerouslySetInnerHTML={{
												__html: car.Description,
											}}
										></ExpansionPanelDetails>
									</ExpansionPanel>
								)}
								{car.Specifications && (
									<ExpansionPanel square>
										<ExpansionPanelSummary expandIcon={<ArrowAccordion />}>
											<p {...bem('accordion-heading')}>
												{t('kinto.car-brick.specification')}
											</p>
										</ExpansionPanelSummary>
										<ExpansionPanelDetails
											dangerouslySetInnerHTML={{
												__html: car.Specifications,
											}}
										></ExpansionPanelDetails>
									</ExpansionPanel>
								)}
							</div>
						</div>
					</Container>
				</div>
			)}
		</BrickContainer>
	)
}

export default CarBrick
