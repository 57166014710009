import './StationFinderBrick.scss'

import React from 'react'
import BEMHelper from 'react-bem-helper'
import useTranslations from '@src/hooks/useTranslations'
import Container from '@material-ui/core/Container'
import Picture from '@src/components/Picture'
import StationListSearch from '@src/components/StationListSearch'
import BrickContainer from '@src/components/bricks/BrickContainer'

const bem = new BEMHelper('station-finder-brick')

const StationFinderBrick = ({ ...data }) => {
	const t = useTranslations()
	return (
		<BrickContainer data={data}>
			<div {...bem('')} id={'station-finder-brick'}>
				<div {...bem('image-container')}>
					{data.images &&
						data.imageAlt &&
						data.images.map((image, index) => (
							<div key={index} {...bem('image')}>
								<Picture {...image} alt={data.imageAlt} />
							</div>
						))}
					<div {...bem('container')}>
						<Container>
							<div {...bem('text')}>
								{data.text && <h1 className='display'>{data.text}</h1>}
							</div>
							<div {...bem('search')}>
								{data.text && (
									<StationListSearch
										country={'se'}
										label={t('kinto.station-brick.search-parking')}
										noResults={t('kinto.station-brick.no-hits')}
									/>
								)}
							</div>
						</Container>
					</div>
				</div>
			</div>
		</BrickContainer>
	)
}

export default StationFinderBrick
