import './NewCarBrick.scss'

import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useConnect } from 'redux-bundler-hook'
import BEMHelper from 'react-bem-helper'
import useSetting from '@src/hooks/useSetting'
import Slider from 'react-slick'
import useTranslations from '@src/hooks/useTranslations'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import NextIcon from '@material-ui/icons/ArrowForwardIosRounded'
import BackIcon from '@material-ui/icons/ArrowBackIosRounded'
import BrickContainer from '@src/components/bricks/BrickContainer'
import Picture from '@src/components/Picture'
import getThumbnailsFromCar from '@src/utils/getThumbnailsFromCar'
import Link from '@src/components/Link'
import { navigate } from 'gatsby'
import { localizedUrl } from '@src/utils/navigate'

const bem = new BEMHelper('new-car-brick')

const Arrow = ({ currentSlide, slideCount, children, ...props }) => (
	<div {...props}>{children}</div>
)

const NewCarBrick = ({ ...data }) => {
	const { pimcore } = useStaticQuery(
		graphql`
			query VehicleTypeQueryNewCarBrick {
				pimcore {
					getVehicleTypeListing {
						edges {
							node {
								id
								Model
								Brand
								NoOfSeats
								AddtionalInformation
								Size
								Towbar
								Specifications
								Description
								VehicleType
								RidecellID
								RatePerHour
								RatePerKilometer
								RatePerHourBusiness
								RatePerKilometerBusiness
								FlexPrice
								FlexPriceBusiness
								FlexPriceExtraText
								Images {
									image {
										filename
										fullpath(thumbnail: "car_brick")
										srcset(thumbnail: "car_brick") {
											descriptor
											url
											resolutions {
												url
												resolution
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`,
	)

	const { doSetBookingSelectedCar, language, service } = useConnect(
		'doSetBookingSelectedCar',
		'selectLanguage',
		'selectService',
	)
	const t = useTranslations()
	const getSetting = useSetting()
	const cdnHost = getSetting('CDNHost', '')
	const [expanded, setExpanded] = useState(false)

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false)
	}

	const vehicleTypeId = data.vehicletype ? data.vehicletype.id : null
	let car = null
	if (vehicleTypeId) {
		const cars = pimcore.getVehicleTypeListing.edges.map(({ node }) => node)
		car = cars.find((car) => parseInt(car.id) === vehicleTypeId)
	}
	let images = null
	if (car) {
		images = getThumbnailsFromCar(car)
	}

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: (
			<Arrow>
				<NextIcon />
			</Arrow>
		),
		prevArrow: (
			<Arrow>
				<BackIcon />
			</Arrow>
		),
	}

	return (
		<BrickContainer data={data}>
			{car && (
				<div {...bem('')} id={'new-car-brick'}>
					<div {...bem('blue-background')}>
						<Container>
							<div {...bem('carousel')}>
								{car.Images && (
									<Slider {...settings}>
										{images.map((image) => {
											return (
												<Picture
													key={car.Brand}
													{...image[0]}
													alt={data.imageAlt}
												/>
											)
										})}
									</Slider>
								)}
							</div>
							<div {...bem('container-top')}>
								<div {...bem('content')}>
									<div {...bem('text')}>
										<h1>{car.Brand}</h1>
										<h3>{car.Model}</h3>
									</div>
									<div {...bem('text', 'right')}>
										<h2>
											{data.flex && data.business
												? car.FlexPriceBusiness
												: data.flex
												? car.FlexPrice
												: data.business
												? t('kinto.booking.ratePerHour', [
														car.RatePerHourBusiness,
												  ])
												: t('kinto.booking.ratePerHour', [car.RatePerHour])}
										</h2>
										<p className='intro'>
											{data.flex
												? car.FlexPriceExtraText
												: data.business
												? t('kinto.booking.ratePerKilometerShort', [
														car.RatePerKilometerBusiness,
												  ])
												: t('kinto.booking.ratePerKilometerShort', [
														car.RatePerKilometer,
												  ])}
										</p>
									</div>
								</div>
							</div>
						</Container>
					</div>
					<div
						{...bem('background-image')}
						style={{ backgroundImage: `url(${cdnHost}${data.image})` }}
					>
						<Container>
							<div {...bem('container-content')}>
								<div {...bem('content')}>
									<div {...bem('container-left')}>
										<p className='large'>{car.AddtionalInformation}</p>
										{data.link.target && data.link.text && (
											<Button
												className='ButtonOrange'
												color='primary'
												variant='contained'
												fullWidth
												component={Link}
												to={
													data.link.target !== 'selectCar' && data.link.target
												}
												{...(data.link.target === 'selectCar' && {
													onClick: () => {
														doSetBookingSelectedCar(car)
														navigate(
															localizedUrl('/boka-bil', language, service),
														)
													},
												})}
											>
												{data.link.text}
											</Button>
										)}
									</div>
									<div {...bem('container-right')}>
										{Object.values(data.blockEditables).map((item) => {
											if (item.title && item.text) {
												return (
													<div key={item.title}>
														<p>{item.title}</p>
														<p className='h5'>{item.text}</p>
													</div>
												)
											}
											return null
										})}
									</div>
								</div>
								{Object.values(data.blockEditablesExpandable).length > 0 && (
									<ExpansionPanel
										expanded={expanded === 'panel'}
										onChange={handleChange('panel')}
									>
										<ExpansionPanelSummary>
											<p>
												{expanded
													? t('kinto.car-brick.show-less')
													: t('kinto.car-brick.show-more')}
											</p>
										</ExpansionPanelSummary>
										<ExpansionPanelDetails>
											{Object.values(data.blockEditablesExpandable).map(
												(item) => {
													if (item.title && item.text) {
														return (
															<>
																<span>{item.title}</span>
																<span className='h5'>{item.text}</span>
															</>
														)
													}
													return null
												},
											)}
										</ExpansionPanelDetails>
									</ExpansionPanel>
								)}
							</div>
						</Container>
					</div>
				</div>
			)}
		</BrickContainer>
	)
}

export default NewCarBrick
