import './NewImageAndTextBrick.scss'
import React, { useEffect, useState } from 'react'
import BEMHelper from 'react-bem-helper'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import { ReactComponent as Arrow } from '@src/assets/icons/chevron_right.svg'
import BrickContainer from '@src/components/bricks/BrickContainer'
import Thumbnails from '@src/components/Thumbnails'
import Link from '@src/components/Link'
import { tabletSize } from '@src/javascript/variables'
import Wysiwyg from '@src/components/bricks/editables/Wysiwyg'

const bem = new BEMHelper('new-image-and-text-brick')

const NewImageAndTextBrick = ({ ...data }) => {
	const [isTablet, setIsTablet] = useState(true)

	useEffect(() => {
		const handleResize = () => {
			setIsTablet(
				typeof window === 'undefined' || window.innerWidth > tabletSize,
			)
		}
		window.addEventListener('resize', handleResize)
		setIsTablet(typeof window === 'undefined' || window.innerWidth > tabletSize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<BrickContainer data={data}>
			<div
				{...(data.bigGrid ? { ...bem('', 'big-grid') } : { ...bem('') })}
				id={'new-image-and-text-brick'}
			>
				<Container maxWidth={data.bigGrid && 'md'}>
					<div
						{...(data.imageRight && isTablet
							? { ...bem('container', 'image-right') }
							: { ...bem('container') })}
					>
						<div
							{...(data.bigGrid && isTablet
								? { ...bem('image-container', 'big-grid') }
								: { ...bem('image-container') })}
						>
							<Thumbnails data={data} bemClass={'new-image-and-text-brick'} />
						</div>
						<div
							{...(data.bigGrid && data.imageRight && isTablet
								? { ...bem('info-container', 'image-right big-grid') }
								: data.imageRight && isTablet
								? { ...bem('info-container', 'image-right') }
								: data.bigGrid && isTablet
								? { ...bem('info-container', 'big-grid') }
								: { ...bem('info-container') })}
						>
							<div {...bem('text-container')}>
								{data.title && (
									<div {...bem('title')}>
										<h2>{data.title}</h2>
									</div>
								)}
								{data.wysiwygText.data !== '' &&
								data.wysiwygText.data !== null ? (
									<Wysiwyg content={data.wysiwygText.data} />
								) : (
									data.text && <p {...bem('text')}>{data.text}</p>
								)}
							</div>
							<div {...bem('bottom-container')}>
								{data.button.target && data.button.linkText && (
									<div {...bem('button')}>
										<Button
											fullWidth
											aria-label={data.button.linkText}
											color='primary'
											variant='contained'
											size='large'
											to={data.button.target}
											component={Link}
										>
											{data.button.linkText}
										</Button>
									</div>
								)}
								{data.link.target && data.link.linkText && (
									<Link to={data.link.target} {...bem('link')}>
										<p>{data.link.linkText}</p>
										<Arrow />
									</Link>
								)}
							</div>
						</div>
					</div>
				</Container>
			</div>
		</BrickContainer>
	)
}

export default NewImageAndTextBrick
