import './NewCarsBrick.scss'

import React, { useEffect, useState } from 'react'
import BEMHelper from 'react-bem-helper'
import Slider from 'react-slick'
import useSetting from '@src/hooks/useSetting'
import useTranslations from '@src/hooks/useTranslations'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import NextIcon from '@material-ui/icons/ArrowForwardIosRounded'
import BackIcon from '@material-ui/icons/ArrowBackIosRounded'
import Link from '@src/components/Link'
import BrickContainer from '@src/components/bricks/BrickContainer'
import { smallDesktopSize, desktopSize } from '@src/javascript/variables'

const bem = new BEMHelper('new-cars-brick')

const Arrow = ({ currentSlide, slideCount, children, ...props }) => (
	<div {...props}>{children}</div>
)

const NewCarsBrick = ({ ...data }) => {
	const t = useTranslations()
	const getSetting = useSetting()
	const cdnHost = getSetting('CDNHost', '')
	const [isDesktop, setIsDesktop] = useState(true)
	const [isLargeDesktop, setIsLargeDesktop] = useState(true)

	useEffect(() => {
		const handleResize = () => {
			setIsDesktop(
				typeof window === 'undefined' || window.innerWidth > smallDesktopSize,
			)
			setIsLargeDesktop(
				typeof window === 'undefined' || window.innerWidth > desktopSize,
			)
		}
		window.addEventListener('resize', handleResize)
		setIsDesktop(
			typeof window === 'undefined' || window.innerWidth > smallDesktopSize,
		)
		setIsLargeDesktop(
			typeof window === 'undefined' || window.innerWidth > desktopSize,
		)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const settings = {
		slidesToScroll: 1,
		slidesToShow: 4,
		dots: true,
		variableWidth: false,
		focusOnSelect: true,
		infinite: false,
		nextArrow: (
			<Arrow>
				<NextIcon />
			</Arrow>
		),
		prevArrow: (
			<Arrow>
				<BackIcon />
			</Arrow>
		),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					variableWidth: true,
					slidesToShow: 1,
				},
			},
		],
	}

	return (
		<BrickContainer data={data}>
			<div {...bem('')} id={'new-cars-brick'}>
				<Container maxWidth={data.bigGrid && 'md'}>
					<div
						{...(data.bigGrid && isDesktop
							? { ...bem('container', 'big-grid') }
							: { ...bem('container') })}
					>
						<div {...bem('container-text')}>
							{data.brickTitle && <h2 className='h1'>{data.brickTitle}</h2>}
							{data.brickText && <p>{data.brickText}</p>}
						</div>
						<Slider
							{...settings}
							{...(data.bigGrid &&
								isLargeDesktop &&
								(settings.slidesToShow = 5))}
						>
							{Object.values(data.blockEditables).map((item) => {
								if (item.title || item.image) {
									return (
										<div {...bem('car')} key={item.title}>
											<Link
												to={item.notActive ? null : item.linkPath}
												{...bem('car-link')}
											>
												{item.image && item.imageAlt && (
													<img
														src={`${cdnHost}${item.image}`}
														alt={item.imageAlt}
														className={item.notActive ? 'not-active' : 'active'}
													/>
												)}
												<div {...bem('car-name')}>
													<h4>{item.title}</h4>
													<p {...bem('car-model')}>{item.model}</p>
												</div>
											</Link>
											<div {...bem('container-bottom')}>
												{item.notActive ? (
													<Button fullWidth variant='contained' disabled={true}>
														{item.linkText
															? item.linkText
															: t('kinto.general.coming-soon')}
													</Button>
												) : (
													<Button
														fullWidth
														color='primary'
														variant='outlined'
														to={item.linkPath}
														component={Link}
													>
														{item.linkText
															? item.linkText
															: t('kinto.general.read-more')}
													</Button>
												)}
											</div>
										</div>
									)
								}
								return null
							})}
						</Slider>
					</div>
				</Container>
			</div>
		</BrickContainer>
	)
}

export default NewCarsBrick
