import { findEditableByName } from '@src/utils/BrickResolver'
import SnippetBrick from '@src/components/bricks/SnippetBrick'
import React from 'react'

/**
 * 			"editables": [{
				"type": "wysiwyg",
				"name": "content",
				"value": "\u003Cp\u003E\u003Cs\u003ELorem ipsum\u003C\/s\u003E dolotum ac urna eget gravida.\u003C\/p\u003E\n"
			}, {
				"type": "input",
				"name": "header",
				"value": "Anv\u00e4ndarvilkor"
			}, {
				"type": "input",
				"name": "linkText",
				"value": "anv\u00e4ndarvilkor"
			}],

 * @param brick
 * @returns {*}
 */
const snippetBrickResolve = (brick) => {
	// TODO: We should iterate through all editables and render them dynamically, like the little mini-page it actually is?
	const data = {
		snippet: {
			content: findEditableByName(brick.editables, 'content').value,
			header: findEditableByName(brick.editables, 'header').value,
			linkText: findEditableByName(brick.editables, 'linkText').value,
		},
	}

	return <SnippetBrick {...data} key={brick.key} />
}

export default snippetBrickResolve
