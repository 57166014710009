import './StepBrick.scss'

import React from 'react'
import BEMHelper from 'react-bem-helper'
import { Container, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import BrickContainer from '@src/components/bricks/BrickContainer'
import Link from '@src/components/Link'

const bem = new BEMHelper('step-brick')

const useStyles = makeStyles({
	root: { textTransform: 'uppercase' },
})

const StepBrick = ({ ...data }) => {
	const classes = useStyles()

	return (
		<BrickContainer data={data}>
			<div {...bem('')} id={'step-brick'}>
				<Container>
					<div {...bem('container')}>
						{data.title && <h3 {...bem('title')}>{data.title}</h3>}

						<div {...bem('content-container')}>
							{Object.values(data.blockEditables).map((item, index) => {
								if (item.title || item.text) {
									return (
										<div key={item.text} {...bem('step')}>
											<div {...bem('number')}>
												<p className='h4'>{index + 1}</p>
											</div>
											<strong>{item.title}</strong>
											<p {...bem('text')}>{item.text}</p>
										</div>
									)
								}
								return null
							})}
						</div>
						{data.link.target && data.link.linkText && (
							<Button
								className={classes.root}
								color='primary'
								variant='outlined'
								size='large'
								to={data.link.target}
								components={Link}
							>
								{data.link.linkText}
							</Button>
						)}
					</div>
				</Container>
			</div>
		</BrickContainer>
	)
}

export default StepBrick
