import './ColumnCheckBrick.scss'
import React, { useEffect, useState } from 'react'
import BEMHelper from 'react-bem-helper'
import Container from '@material-ui/core/Container'
import BrickContainer from '@src/components/bricks/BrickContainer'
import { smallDesktopSize } from '@src/javascript/variables'
import { ReactComponent as Check } from '@src/assets/icons/blackCheck.svg'

const bem = new BEMHelper('column-check-brick')

const ColumnCheckBrick = ({ ...data }) => {
	const [isDesktop, setIsDesktop] = useState(true)

	useEffect(() => {
		const handleResize = () => {
			setIsDesktop(
				typeof window === 'undefined' || window.innerWidth > smallDesktopSize,
			)
		}
		window.addEventListener('resize', handleResize)
		setIsDesktop(
			typeof window === 'undefined' || window.innerWidth > smallDesktopSize,
		)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<BrickContainer data={data}>
			<div
				{...(data.bigGrid && isDesktop
					? { ...bem('', 'big-grid') }
					: { ...bem('') })}
				id={'column-check-brick'}
			>
				<Container maxWidth={data.bigGrid && isDesktop && 'md'}>
					<div {...bem('content')}>
						<h3>{data.title}</h3>
						<div {...bem('check-container')}>
							{Object.values(data.blockEditables).map((item) => {
								return (
									<div {...bem('check')}>
										<Check />
										<p>{item.checkText}</p>
									</div>
								)
							})}
						</div>
					</div>
				</Container>
			</div>
		</BrickContainer>
	)
}

export default ColumnCheckBrick
