import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import getThumbnailsFromBricks from '@src/utils/getThumbnailsFromBricks'
import AppBrick from '@src/components/bricks/AppBrick'
import React from 'react'

const appBrickResolve = (brick) => {
	const images = getThumbnailsFromBricks(brick, 'image')
	const margins = getMarginsFromBricks(brick)

	const data = {
		margins,
		images: images,
		imageAlt: findEditableByName(brick.editables, 'image').title,
		title: findEditableByName(brick.editables, 'title').value,
		text: findEditableByName(brick.editables, 'text').value,
		linkAppStore: findEditableByName(brick.editables, 'linkAppStore').value,
		imageAppStore: findEditableByName(brick.editables, 'imageAppStore').value,
		imageAppStoreAlt: findEditableByName(brick.editables, 'imageAppStore')
			.title,
		linkGooglePlay: findEditableByName(brick.editables, 'linkGooglePlay').value,
		imageGooglePlay: findEditableByName(brick.editables, 'imageGooglePlay')
			.value,
		imageGooglePlayAlt: findEditableByName(brick.editables, 'imageGooglePlay')
			.title,
	}

	return <AppBrick {...data} key={brick.key}></AppBrick>
}

export default appBrickResolve
