import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import CarsBrick from '@src/components/bricks/CarsBrick'
import React from 'react'

const carsBrickResolve = (brick) => {
	const blockEditablesArray = findEditableByName(
		brick.editables,
		'contentblock',
	)
	var blockEditables = {}
	const arrayLength =
		blockEditablesArray && blockEditablesArray.objects
			? blockEditablesArray.objects.length
			: 0

	for (var i = 0; i < arrayLength; i++) {
		const editable = blockEditablesArray.objects[i]

		const editableIndexRegex = /contentblock:([0-9]+)/i
		const regexResult = editableIndexRegex.exec(editable.name)
		const editableIndex = parseInt(regexResult[1])

		const notActiveRegex = /notActive/gi
		const isNotActive = notActiveRegex.test(editable.name)

		const carImageRegex = /carImage/gi
		const isCarImage = carImageRegex.test(editable.name)

		const carNameRegex = /carName/gi
		const isCarName = carNameRegex.test(editable.name)

		const linkRegex = /link/gi
		const isLink = linkRegex.test(editable.name)

		if (blockEditables['car' + editableIndex] == null) {
			blockEditables['car' + editableIndex] = {}
		}

		if (isNotActive) {
			blockEditables['car' + editableIndex] = Object.assign(
				{ notActive: editable.value },
				blockEditables['car' + editableIndex],
			)
		} else if (isCarImage) {
			blockEditables['car' + editableIndex] = Object.assign(
				{ image: editable.value },
				blockEditables['car' + editableIndex],
			)
			blockEditables['car' + editableIndex] = Object.assign(
				{ imageAlt: editable.title },
				blockEditables['car' + editableIndex],
			)
		} else if (isCarName) {
			blockEditables['car' + editableIndex] = Object.assign(
				{ title: editable.value },
				blockEditables['car' + editableIndex],
			)
		} else if (isLink) {
			blockEditables['car' + editableIndex] = Object.assign(
				{ linkText: editable.text },
				{ linkPath: editable.value },
				blockEditables['car' + editableIndex],
			)
		}
	}

	const margins = getMarginsFromBricks(brick)

	const data = {
		margins,
		brickTitle: findEditableByName(brick.editables, 'title').value,
		brickText: findEditableByName(brick.editables, 'text').value,
		blockEditables,
	}

	return <CarsBrick {...data} key={brick.key}></CarsBrick>
}

export default carsBrickResolve
