import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import VideoBrick from '@src/components/bricks/VideoBrick'
import React from 'react'

const videoBrickResolve = (brick) => {
	const margins = getMarginsFromBricks(brick)

	const data = {
		margins,
		title: findEditableByName(brick.editables, 'title').value,
		video: findEditableByName(brick.editables, 'video').path,
		blueButton: findEditableByName(brick.editables, 'blue-button').value,
		blueText: findEditableByName(brick.editables, 'blue-text').value,
		link: {
			linkText: findEditableByName(brick.editables, 'link').text,
			target: findEditableByName(brick.editables, 'link').value,
		},
	}

	return <VideoBrick {...data} key={brick.key}></VideoBrick>
}

export default videoBrickResolve
