import React from 'react'
import { Container } from '@material-ui/core'
import BrickContainer from '@src/components/bricks/BrickContainer'
import useTranslations from '@src/hooks/useTranslations'
import Button from '@material-ui/core/Button'

const CookiesBrick = ({ ...data }) => {
	const t = useTranslations()
	return (
		<BrickContainer data={data}>
			<Container>
				<div id='ot-sdk-cookie-policy'></div>
				<Button
					variant='contained'
					color='primary'
					className='ot-sdk-show-settings'
				>
					{t('kinto.cookies-brick.show-settings')}
				</Button>
			</Container>
		</BrickContainer>
	)
}

export default CookiesBrick
