import './MapBrick.scss'
import React, { useEffect, useState } from 'react'
import BEMHelper from 'react-bem-helper'
import { azureApiGatewayGet } from '@src/api/azureApiGateway/client'
import { useScript } from 'use-hooks'
import useSetting from '@src/hooks/useSetting'
import { mobileSize, tabletSize } from '@src/javascript/variables'
import Container from '@material-ui/core/Container'
import BrickContainer from '@src/components/bricks/BrickContainer'
import Map from '@src/components/Map'

const bem = new BEMHelper('map-brick')

const MapBrick = ({ ...data }) => {
	const getSetting = useSetting()
	const googleMapUri = getSetting('googleMapUri', process.env.GOOGLE_MAPS_URL)
	const [loaded, loadError] = useScript(googleMapUri)
	const [isMobile, setIsMobile] = useState(true)
	const [isTablet, setIsTablet] = useState(true)
	const [stations, setStations] = useState([])
	const [selectedLocation, setSelectedLocation] = useState(null)

	useEffect(() => {
		const handleResize = () => {
			setIsTablet(
				typeof window === 'undefined' || window.innerWidth < tabletSize,
			)
			setIsMobile(
				typeof window === 'undefined' || window.innerWidth < mobileSize,
			)
		}
		window.addEventListener('resize', handleResize)
		setIsTablet(typeof window === 'undefined' || window.innerWidth < tabletSize)
		setIsMobile(typeof window === 'undefined' || window.innerWidth < mobileSize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const updateLinkedLocation = async (selLoc) => {
		try {
			const geoData = await getLocationFromURL(selLoc)

			setSelectedLocation({ geoData })
		} catch (e) {
			console.log('Trying to determine latlang before api init, needs a fix', e)
		}
	}

	const getLocationFromURL = (value) => {
		return new Promise((resolve, reject) => {
			if (typeof window === 'undefined' || !window.google) {
				return reject(new Error('Google Maps Api not initialized'))
			}
			const geocoder = new window.google.maps.Geocoder()
			geocoder.geocode({ address: value + ', Sweden' }, (results, status) => {
				if (status !== 'OK') {
					reject()
				} else {
					resolve(results[0])
				}
			})
		})
	}

	useEffect(() => {
		if (stations.length <= 0) {
			runAzureRequest()
		}
		if (loaded && data.area) {
			updateLinkedLocation(data.area)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stations, loaded])

	async function runAzureRequest() {
		const response = await azureApiGatewayGet('station')
		setStations(
			response.data && response.data.stations ? response.data.stations : [],
		)
	}

	return (
		<BrickContainer data={data}>
			{data.area && data.zoom && data.zoomTablet && data.zoomMobile && (
				<div {...bem('')} id={'map-brick'}>
					<Container>
						{loaded && !loadError && stations !== [] && (
							<Map
								brick={true}
								loaded={loaded}
								loadError={loadError}
								width={'auto'}
								height={isMobile ? 375 : isTablet ? 768 : 1000}
								stations={stations}
								showNearByStations={true}
								chosenFilters={[]}
								zoomControl={false}
								brickZoom={
									isMobile
										? data.zoomMobile
										: isTablet
										? data.zoomTablet
										: data.zoom
								}
								selectedLocation={selectedLocation}
							/>
						)}
					</Container>
				</div>
			)}
		</BrickContainer>
	)
}

export default MapBrick
