import { get } from 'lodash'

export default function getThumbnailsFromCar(car) {
	if (!car.Images) {
		return [
			{
				default: '',
			},
		]
	}
	if (car.Images.length === 0) {
		return [
			{
				default: '',
			},
		]
	}

	if (get(car.Images[0], 'image.fullpath') === undefined) {
		return [
			{
				default: '',
			},
		]
	}

	if (car.Images.length > 0) {
		return car.Images.map((image) => {
			const imageList = []
			image.image.srcset.map((item) => {
				imageList.push({
					xsmall: item.descriptor === '576w' ? item.url : null,
					small: item.descriptor === '768w' ? item.url : null,
					medium: item.descriptor === '1024w' ? item.url : null,
					large: item.descriptor === '1366w' ? item.url : null,
					xlarge: item.descriptor === '1680w' ? item.url : null,
					defaultImage: get(image, 'image.fullpath')
						? get(image, 'image.fullpath')
						: null,
				})
				return null
			})
			return imageList
		})
	}
}
