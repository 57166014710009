import './InformationBrick.scss'

import React from 'react'
import BEMHelper from 'react-bem-helper'
import useSetting from '@src/hooks/useSetting'
import { Container, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import BrickContainer from '@src/components/bricks/BrickContainer'
import Link from '@src/components/Link'

const bem = new BEMHelper('information-brick')

const useStyles = makeStyles({
	root: { width: '19rem' },
})

const InformationBrick = ({ ...data }) => {
	const getSetting = useSetting()
	const cdnHost = getSetting('CDNHost', '')
	const classes = useStyles()

	return (
		<BrickContainer data={data}>
			<div {...bem('')} id={'information-brick'}>
				<Container>
					<div {...bem('container')}>
						<div {...bem('content-container')}>
							{Object.values(data.blockEditables).map((item) => {
								if (item.title || item.text || item.image) {
									return (
										<div key={item.title} {...bem('block')}>
											{item.image && item.imageAlt && (
												<img
													src={`${cdnHost}${item.image}`}
													alt={item.imageAlt}
													{...bem('image')}
												/>
											)}
											<div {...bem('text-container')}>
												<h5>{item.title}</h5>
												<p {...bem('text')}>{item.text}</p>
											</div>
										</div>
									)
								}
								return null
							})}
						</div>
						{data.link.target && data.link.linkText && (
							<div {...bem('button')}>
								<Button
									className={classes.root}
									color='primary'
									variant='contained'
									size='large'
									to={data.link.target}
									components={Link}
								>
									{data.link.linkText}
								</Button>
							</div>
						)}
					</div>
				</Container>
			</div>
		</BrickContainer>
	)
}

export default InformationBrick
