import React, { useState } from 'react'
import Modal from '@src/components/Modal'

export default function Consent({ linkText, header, content }) {
	const [showModal, setShowModal] = useState(false)
	return (
		<>
			<button className='button' onClick={() => setShowModal(true)}>
				{linkText}
			</button>
			{showModal && (
				<Modal open={showModal} handleClose={() => setShowModal(false)} xButton>
					<>
						<h3>{header}</h3>
						<div
							dangerouslySetInnerHTML={{
								__html: content,
							}}
						></div>
					</>
				</Modal>
			)}
		</>
	)
}
