import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import MembershipIntroBrick from '@src/components/bricks/MembershipIntroBrick'
import React from 'react'

const membershipIntroBrickResolve = (brick) => {
	const membershipsArray = findEditableByName(
		brick.editables,
		'contentblockLevel',
	)

	var memberships = {}
	const membershipsArrayLength =
		membershipsArray && membershipsArray.objects
			? membershipsArray.objects.length
			: 0

	for (var i = 0; i < membershipsArrayLength; i++) {
		const editable = membershipsArray.objects[i]

		const editableIndexRegex = /contentblockLevel:([0-9]+)/i
		const regexResult = editableIndexRegex.exec(editable.name)
		const editableIndex = parseInt(regexResult[1])

		const notActiveRegex = /notActive/gi
		const isNotActive = notActiveRegex.test(editable.name)

		const membershipNameRegex = /membershipName/gi
		const isMembershipName = membershipNameRegex.test(editable.name)

		const membershipPriceRegex = /membershipPrice/gi
		const isMembershipPrice = membershipPriceRegex.test(editable.name)

		const membershipInfoRegex = /membershipInfo/gi
		const isMembershipInfo = membershipInfoRegex.test(editable.name)

		const membershipLabelRegex = /membershipLabel/gi
		const isMembershipLabel = membershipLabelRegex.test(editable.name)

		const membershipUspsRegex = /contentblockUsp/gi
		const membershipUspsTypeRegex = /block/gi
		const isMembershipUsps =
			membershipUspsRegex.test(editable.name) &&
			membershipUspsTypeRegex.test(editable.type)

		const membershipButtonColorRegex = /buttonColor/gi
		const isMembershipButtonColor = membershipButtonColorRegex.test(
			editable.name,
		)

		const membershipButtonRegex = /button/gi
		const isMembershipButton = membershipButtonRegex.test(editable.name)

		if (memberships['membership' + editableIndex] == null) {
			memberships['membership' + editableIndex] = {}
		}

		if (isNotActive) {
			memberships['membership' + editableIndex] = {
				notActive: editable.value,
				...memberships['membership' + editableIndex],
			}
		} else if (isMembershipName) {
			memberships['membership' + editableIndex] = {
				name: editable.value,
				...memberships['membership' + editableIndex],
			}
		} else if (isMembershipPrice) {
			memberships['membership' + editableIndex] = {
				price: editable.value,
				...memberships['membership' + editableIndex],
			}
		} else if (isMembershipInfo) {
			memberships['membership' + editableIndex] = {
				info: editable.value,
				...memberships['membership' + editableIndex],
			}
		} else if (isMembershipLabel) {
			memberships['membership' + editableIndex] = {
				label: editable.value,
				...memberships['membership' + editableIndex],
			}
		} else if (isMembershipUsps) {
			memberships['membership' + editableIndex].usps = []
			if (editable.objects !== null) {
				editable.objects.forEach((usp) => {
					memberships['membership' + editableIndex].usps = [
						...memberships['membership' + editableIndex].usps,
						usp.value,
					]
				})
			}
		} else if (isMembershipButtonColor) {
			memberships['membership' + editableIndex] = {
				buttonColor: editable.value,
				...memberships['membership' + editableIndex],
			}
		} else if (isMembershipButton) {
			memberships['membership' + editableIndex] = {
				buttonText: editable.text,
				...memberships['membership' + editableIndex],
			}
			memberships['membership' + editableIndex] = {
				buttonLink: editable.value,
				...memberships['membership' + editableIndex],
			}
		}
	}

	const margins = getMarginsFromBricks(brick)

	const data = {
		margins,
		title: findEditableByName(brick.editables, 'title').value,
		text: findEditableByName(brick.editables, 'text').value,
		memberships: memberships,
		bottomButton: {
			text: findEditableByName(brick.editables, 'bottomButton').text,
			target: findEditableByName(brick.editables, 'bottomButton').value,
		},
	}
	return <MembershipIntroBrick {...data} key={brick.key}></MembershipIntroBrick>
}

export default membershipIntroBrickResolve
