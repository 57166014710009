import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import getThumbnailsFromBricks from '@src/utils/getThumbnailsFromBricks'
import NewImageAndTextBrick from '@src/components/bricks/NewImageAndTextBrick'
import React from 'react'

const newImageAndTextBrickResolve = (brick) => {
	const images = getThumbnailsFromBricks(brick, 'image')
	const margins = getMarginsFromBricks(brick)

	const data = {
		margins,
		title: findEditableByName(brick.editables, 'title').value,
		text: findEditableByName(brick.editables, 'text').value,
		wysiwygText: {
			data: findEditableByName(brick.editables, 'wysiwygText')
				? findEditableByName(brick.editables, 'wysiwygText').value
				: null,
		},
		images: images,
		imageAlt: findEditableByName(brick.editables, 'image').title,
		link: {
			linkText: findEditableByName(brick.editables, 'link').text,
			target: findEditableByName(brick.editables, 'link').value,
		},
		button: {
			linkText: findEditableByName(brick.editables, 'button').text,
			target: findEditableByName(brick.editables, 'button').value,
		},
		bigGrid: findEditableByName(brick.editables, 'big-grid').value,
		imageRight: findEditableByName(brick.editables, 'image-right').value,
	}

	return <NewImageAndTextBrick {...data} key={brick.key}></NewImageAndTextBrick>
}

export default newImageAndTextBrickResolve
