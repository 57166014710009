import './VideoBrick.scss'
import React, { useState } from 'react'
import BEMHelper from 'react-bem-helper'
import useSetting from '@src/hooks/useSetting'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import BrickContainer from '@src/components/bricks/BrickContainer'
import { ReactComponent as NoSound } from '@src/assets/icons/no-sound.svg'
import { ReactComponent as Sound } from '@src/assets/icons/volume.svg'
import Link from '@src/components/Link'

const bem = new BEMHelper('video-brick')

const VideoBrick = ({ ...data }) => {
	const getSetting = useSetting()
	const cdnHost = getSetting('CDNHost', '')
	const [sound, setSound] = useState(false)
	const toggleSound = () => setSound(!sound)

	return (
		<BrickContainer data={data}>
			<div {...bem('')} id={'video-brick'}>
				{/*eslint-disable-next-line jsx-a11y/media-has-caption*/}
				<video muted={sound ? false : true} autoPlay loop playsInline>
					<source src={`${cdnHost}/${data.video}`} />
				</video>
				<div {...bem('container')}>
					<Container>
						<div {...bem('content-container')}>
							{data.title && (
								<div
									{...(data.blueText
										? { ...bem('title', 'blue') }
										: { ...bem('title') })}
								>
									<h2 className='display'>{data.title}</h2>
								</div>
							)}
							{data.link.target && data.link.linkText && (
								<div {...bem('button')}>
									<Button
										fullWidth
										color={data.blueButton ? 'primary' : 'secondary'}
										variant='contained'
										size='large'
										to={data.link.target}
										component={Link}
									>
										{data.link.linkText}
									</Button>
								</div>
							)}
						</div>
					</Container>
				</div>
				<div {...bem('icon')}>
					{sound ? (
						<Sound onClick={() => toggleSound()} />
					) : (
						<NoSound onClick={() => toggleSound()} />
					)}
				</div>
			</div>
		</BrickContainer>
	)
}

export default VideoBrick
