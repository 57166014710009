import './StationListSearch.scss'
import React, { useState, useEffect, useMemo } from 'react'
import { useScript } from 'use-hooks'
import { navigate } from 'gatsby'
import BEMHelper from 'react-bem-helper'
import { TextField, Grid } from '@material-ui/core/'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import NearMeIcon from '@material-ui/icons/NearMeOutlined'
import get from 'lodash/get'
import { useConnect } from 'redux-bundler-hook'
import throttle from 'lodash/throttle'
import { ReactComponent as Location } from '@src/assets/icons/geolocation.svg'
import { ReactComponent as Parking } from '@src/assets/icons/parking.svg'
import { azureApiGatewayGet } from '@src/api/azureApiGateway/client'
import useSetting from '@src/hooks/useSetting'
import { localizedUrl } from '@src/utils/navigate'

const autocompleteService = { current: null }

const bem = new BEMHelper('station-list-search')

const StationListSearch = ({ country, label, noResults, onSelected }) => {
	const [inputValue, setInputValue] = useState('')
	const [stations, setStations] = useState([])
	const [locations, setLocations] = useState([])
	const getSetting = useSetting()
	const googleMapUri = getSetting('googleMapUri', process.env.GOOGLE_MAPS_URL)
	const [loaded, loadError] = useScript(googleMapUri)
	const handleChange = (event) => {
		setInputValue(event.target.value)
	}

	const { isCustomerLoggedIn, language, service } = useConnect(
		'selectIsCustomerLoggedIn',
		'selectLanguage',
		'selectService',
	)

	const handleStationChanged = async (event, value, reason) => {
		if (!value) return
		if (value.place_id) {
			navigate(localizedUrl('/boka-bil', language, service), {
				state: {
					selectedLocation: value,
				},
			})
		} else if (value.codename) {
			navigate(localizedUrl('/boka-bil', language, service), {
				state: {
					selectedStation: value,
				},
			})
		}
	}

	const getPlacePredictionsMemo = useMemo(
		() =>
			throttle((request, callback) => {
				autocompleteService.current.getPlacePredictions(request, callback)
			}, 200),
		[],
	)

	async function runAzureRequest() {
		const response = isCustomerLoggedIn
			? await azureApiGatewayGet('station')
			: await azureApiGatewayGet('visitor_station')

		setStations(
			response.data && response.data.stations ? response.data.stations : [],
		)
	}

	useEffect(() => {
		let active = true

		// Only load stations if not already loaded and this is still the active session on return
		if (active && stations && stations.length <= 0) {
			runAzureRequest()
		}

		if (
			!autocompleteService.current &&
			get(window, 'google.maps.places.AutocompleteService')
		) {
			autocompleteService.current = new window.google.maps.places.AutocompleteService()
		}
		if (!autocompleteService.current) {
			return undefined
		}

		if (inputValue !== '') {
			getPlacePredictionsMemo(
				{ input: inputValue, componentRestrictions: { country: country } },
				(results) => {
					if (active) {
						setLocations(results || [])
					}
				},
			)
		} else {
			setLocations([])
		}

		return () => {
			active = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputValue, getPlacePredictionsMemo, country, stations, setLocations])

	const options = stations.concat(locations)

	return (
		<>
			{!loaded && <CircularProgress />}

			{loadError && <div>Error loading</div>}

			{loaded && (
				<div {...bem('')}>
					<Autocomplete
						id='search-parking'
						getOptionLabel={(option) =>
							option.location
								? option.location.display_name
								: option.description
						}
						filterOptions={(options) => {
							function hasValue(valueString) {
								return (
									valueString.toLowerCase().indexOf(inputValue.toLowerCase()) >
									-1
								)
							}
							return options.filter((option) => {
								if (option.location) {
									return (
										hasValue(option.name) ||
										hasValue(option.location.display_name) ||
										hasValue(option.location.formatted_address)
									)
								}
								return true
							})
						}}
						options={options}
						onChange={handleStationChanged}
						onClose={() => setInputValue('')}
						onSelected={onSelected}
						noOptionsText={noResults}
						popupIcon={<NearMeIcon color='primary' />}
						renderInput={(params) => (
							<TextField
								{...params}
								label={label}
								variant='filled'
								fullWidth
								onChange={handleChange}
								InputProps={{
									...params.InputProps,
									disableUnderline: true,
								}}
							/>
						)}
						renderOption={(option) => {
							return (
								<Grid container alignItems='center'>
									<Grid item>
										{option.location ? <Parking /> : <Location />}
									</Grid>
									<Grid item xs>
										<span>
											{option.name
												? option.name
												: option.description.substr(
														0,
														option.description.lastIndexOf(','),
												  )}
										</span>
									</Grid>
								</Grid>
							)
						}}
					/>
				</div>
			)}
		</>
	)
}

export default StationListSearch
