import './MembershipIntroBrick.scss'
import '../CustomButtons.scss'

import React, { useEffect, useState } from 'react'
import BEMHelper from 'react-bem-helper'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import BrickContainer from '@src/components/bricks/BrickContainer'
import Link from '@src/components/Link'
import { ReactComponent as Check } from '@src/assets/icons/check.svg'
import { ReactComponent as Arrow } from '@src/assets/icons/chevron_right.svg'
import { tabletSize, mobileSize } from '@src/javascript/variables'

const bem = new BEMHelper('membership-intro-brick')

const MembershipIntroBrick = ({ ...data }) => {
	const [isTablet, setIsTablet] = useState(true)

	useEffect(() => {
		const handleResize = () => {
			setIsTablet(
				typeof window === 'undefined' ||
					(window.innerWidth >= mobileSize && window.innerWidth < tabletSize),
			)
		}
		window.addEventListener('resize', handleResize)
		setIsTablet(
			typeof window === 'undefined' ||
				(window.innerWidth >= mobileSize && window.innerWidth < tabletSize),
		)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<BrickContainer data={data}>
			<div {...bem('')}>
				<Container>
					<div {...bem('container')}>
						{data.title && <h1>{data.title}</h1>}
						{data.text && <p className='large'>{data.text}</p>}
						<div {...bem('memberships-container')}>
							{Object.values(data.memberships).map((membership) => {
								const price = membership.price.replace(/[^0-9]/g, '')
								const text = membership.price.replace(/[0-9]/g, '')
								const onlyText = /^[a-z]+$/i.test(membership.price)

								return (
									<div
										key={membership.name}
										{...(membership.notActive
											? { ...bem('membership', 'not-active') }
											: { ...bem('membership') })}
									>
										<div {...bem('membership-container')}>
											<div {...bem('heading')}>
												{membership.name && <h3>{membership.name}</h3>}
												{membership.label && (
													<p className='smallBold'>{membership.label}</p>
												)}
											</div>
											<div {...bem('price')}>
												{membership.price && !onlyText ? (
													<>
														<p className='h1'>{price}</p>
														<p className='h4'>{text}</p>
													</>
												) : (
													membership.price &&
													onlyText && <p className='h3'>{membership.price}</p>
												)}
											</div>
											<div {...bem('info')}>
												{membership.info && <p>{membership.info}</p>}
											</div>
											<div {...bem('usps')}>
												{membership.usps.length > 0 &&
													membership.usps.map((usp) => {
														return (
															<div {...bem('usp')}>
																<Check />
																<p>{usp}</p>
															</div>
														)
													})}
											</div>
											<div {...bem('button-container')}>
												{membership.buttonText &&
												membership.buttonLink &&
												!membership.notActive ? (
													<Button
														className={
															membership.buttonColor === 'Orange'
																? 'ButtonOrange'
																: null
														}
														aria-label={membership.buttonText}
														color={'primary'}
														variant={
															membership.buttonColor === 'Blue' ||
															membership.buttonColor === 'Orange'
																? 'contained'
																: membership.buttonColor === 'White' &&
																  'outlined'
														}
														size='large'
														to={membership.buttonLink}
														component={Link}
														disabled={membership.notActive}
													>
														{membership.buttonText}
													</Button>
												) : (
													membership.notActive && (
														<p className='h6'>{membership.buttonText}</p>
													)
												)}
											</div>
										</div>
									</div>
								)
							})}
							{isTablet && Object.keys(data.memberships).length === 3 && (
								<div {...bem('membership', 'link')}>
									{data.bottomButton.target && data.bottomButton.text && (
										<Link to={data.bottomButton.target} {...bem('link')}>
											<p>{data.bottomButton.text}</p>
											<Arrow />
										</Link>
									)}
								</div>
							)}
						</div>
						{((isTablet && Object.keys(data.memberships).length === 4) ||
							!isTablet) &&
							data.bottomButton.target &&
							data.bottomButton.text && (
								<div {...bem('link-container')}>
									<Link to={data.bottomButton.target} {...bem('link')}>
										<p>{data.bottomButton.text}</p>
										<Arrow />
									</Link>
								</div>
							)}
					</div>
				</Container>
			</div>
		</BrickContainer>
	)
}

export default MembershipIntroBrick
