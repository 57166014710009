import React, { useRef, useEffect } from 'react'
import { navigate } from 'gatsby'

function handleNavigation(event) {
	navigate(event.target.pathname)
	event.stopPropagation()
	event.preventDefault()
}

const Wysiwyg = React.memo(({ content }) => {
	const wysiwygEl = useRef(null)
	useEffect(() => {
		const anchors = wysiwygEl.current.getElementsByTagName('a')

		for (let anchor of anchors) {
			if (anchor.host === window.location.host) {
				anchor.addEventListener('click', handleNavigation, true)
			}
		}

		return () => {
			for (let anchor of anchors) {
				if (anchor.host === window.location.host) {
					anchor.removeEventListener('click', handleNavigation)
				}
			}
		}
	})

	return (
		<div
			ref={wysiwygEl}
			dangerouslySetInnerHTML={{
				__html: content,
			}}
		></div>
	)
})

export default Wysiwyg
