import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import LinkBrick from '@src/components/bricks/LinkBrick'
import React from 'react'

const linksBrickResolve = (brick) => {
	const margins = getMarginsFromBricks(brick)
	const data = {
		margins,
		link1: {
			text: findEditableByName(brick.editables, 'link1').text,
			target: findEditableByName(brick.editables, 'link1').value,
		},
		link2: {
			text: findEditableByName(brick.editables, 'link2').text,
			target: findEditableByName(brick.editables, 'link2').value,
		},
		link3: {
			text: findEditableByName(brick.editables, 'link3').text,
			target: findEditableByName(brick.editables, 'link3').value,
		},
	}

	return <LinkBrick {...data} key={brick.key}></LinkBrick>
}

export default linksBrickResolve
