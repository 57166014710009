import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import getThumbnailsFromBricks from '@src/utils/getThumbnailsFromBricks'
import ImageAndTextBrick from '@src/components/bricks/ImageAndTextBrick'
import React from 'react'

const imageAndTextBrickResolve = (brick) => {
	const images = getThumbnailsFromBricks(brick, 'imageAndTextImage')
	const margins = getMarginsFromBricks(brick)

	const data = {
		margins,
		title: findEditableByName(brick.editables, 'imageAndTextTitle').value,
		text: findEditableByName(brick.editables, 'imageAndTextText').value,
		images: images,
		imageAlt: findEditableByName(brick.editables, 'imageAndTextImage').title,

		link: {
			linkText: findEditableByName(brick.editables, 'link').text,
			target: findEditableByName(brick.editables, 'link').value,
		},
	}

	return <ImageAndTextBrick {...data} key={brick.key}></ImageAndTextBrick>
}

export default imageAndTextBrickResolve
