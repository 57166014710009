import React from 'react'
import BEMHelper from 'react-bem-helper'
import { Container } from '@material-ui/core'
import Wysiwyg from '@src/components/bricks/editables/Wysiwyg'
const bem = new BEMHelper('snippet-brick')

const SnippetBrick = ({ ...data }) => {
	return (
		<div {...bem('')}>
			<Container>
				{data.snippet.header && (
					<h3 {...bem('title')}>{data.snippet.header}</h3>
				)}

				<div {...bem('container')}>
					<Wysiwyg content={data.snippet.content} />
				</div>
			</Container>
		</div>
	)
}

export default SnippetBrick
