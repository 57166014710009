import { findEditableByName } from '@src/utils/BrickResolver'

export default function getThumbnailsFromBricks(brick, editableName) {
	const images = []

	const customImage = findEditableByName(brick.editables, editableName)
		? findEditableByName(brick.editables, editableName)
		: null

	if (customImage) {
		images.push({
			xsmall: customImage.xsThumbnail ? customImage.xsThumbnail.value : null,
			small: customImage.smThumbnail ? customImage.smThumbnail.value : null,
			medium: customImage.mdThumbnail ? customImage.mdThumbnail.value : null,
			large: customImage.lgThumbnail ? customImage.lgThumbnail.value : null,
			xlarge: customImage.xlThumbnail ? customImage.xlThumbnail.value : null,
			defaultImage: customImage.value,
		})
	}
	return images
}
