import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import HowToBrick from '@src/components/bricks/HowToBrick'
import React from 'react'

const howToBrickResolve = (brick) => {
	const blockEditablesArray = findEditableByName(
		brick.editables,
		'contentblock',
	)
	var blockEditables = {}
	const arrayLength =
		blockEditablesArray && blockEditablesArray.objects
			? blockEditablesArray.objects.length
			: 0

	for (var i = 0; i < arrayLength; i++) {
		const editable = blockEditablesArray.objects[i]

		const editableIndexRegex = /contentblock:([0-9]+)/i
		const regexResult = editableIndexRegex.exec(editable.name)
		const editableIndex = parseInt(regexResult[1])

		const informationImageRegex = /informationImage/gi
		const isInformationImage = informationImageRegex.test(editable.name)

		const informationTitleRegex = /informationTitle/gi
		const isInformationTitle = informationTitleRegex.test(editable.name)

		const informationTextRegex = /informationText/gi
		const isInformationText = informationTextRegex.test(editable.name)

		if (blockEditables['information' + editableIndex] == null) {
			blockEditables['information' + editableIndex] = {}
		}

		if (isInformationImage) {
			blockEditables['information' + editableIndex] = {
				image: editable.value,
				...blockEditables['information' + editableIndex],
			}
			blockEditables['information' + editableIndex] = {
				imageAlt: editable.title,
				...blockEditables['information' + editableIndex],
			}
		} else if (isInformationTitle) {
			blockEditables['information' + editableIndex] = {
				title: editable.value,
				...blockEditables['information' + editableIndex],
			}
		} else if (isInformationText) {
			blockEditables['information' + editableIndex] = {
				text: editable.value,
				...blockEditables['information' + editableIndex],
			}
		}
	}

	const margins = getMarginsFromBricks(brick)

	const data = {
		margins,
		blockEditables,
		bigGrid: findEditableByName(brick.editables, 'big-grid').value,
		title: findEditableByName(brick.editables, 'title').value,
		text: findEditableByName(brick.editables, 'text').value,
		link: {
			linkText: findEditableByName(brick.editables, 'link').text,
			target: findEditableByName(brick.editables, 'link').value,
		},
	}

	return <HowToBrick {...data} key={brick.key}></HowToBrick>
}

export default howToBrickResolve
