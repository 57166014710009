import { findEditableByName } from '@src/utils/BrickResolver'
import getMarginsFromBricks from '@src/utils/getMarginsFromBricks'
import WysiwygBrick from '@src/components/bricks/WysiwygBrick'
import React from 'react'

const wysiwygBrickResolve = (brick) => {
	const margins = getMarginsFromBricks(brick)
	const data = {
		margins,
		wysiwyg: {
			data: findEditableByName(brick.editables, 'wysiwyg').value,
		},
	}

	return <WysiwygBrick {...data} key={brick.key} />
}

export default wysiwygBrickResolve
