import './NewAppBrick.scss'

import React, { useEffect, useState } from 'react'
import BEMHelper from 'react-bem-helper'
import useSetting from '@src/hooks/useSetting'
import Container from '@material-ui/core/Container'
import Link from '@src/components/Link'
import BrickContainer from '@src/components/bricks/BrickContainer'
import Thumbnails from '@src/components/Thumbnails'
import { smallDesktopSize } from '@src/javascript/variables'

const bem = new BEMHelper('new-app-brick')

const NewAppBrick = ({ ...data }) => {
	const getSetting = useSetting()
	const cdnHost = getSetting('CDNHost', '')
	const [isDesktop, setIsDesktop] = useState(true)

	useEffect(() => {
		const handleResize = () => {
			setIsDesktop(
				typeof window === 'undefined' || window.innerWidth > smallDesktopSize,
			)
		}
		window.addEventListener('resize', handleResize)
		setIsDesktop(
			typeof window === 'undefined' || window.innerWidth > smallDesktopSize,
		)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<BrickContainer data={data}>
			<div {...bem('')} id={'new-app-brick'}>
				<Container maxWidth={data.bigGrid && 'md'}>
					<div
						{...(data.bigGrid && isDesktop
							? { ...bem('container', 'big-grid') }
							: { ...bem('container') })}
					>
						<div {...bem('image-container')}>
							<Thumbnails data={data} bemClass={'new-app-brick'} />
						</div>

						<div {...bem('text-container')}>
							<div
								{...(data.bigGrid && isDesktop
									? { ...bem('text-container-inner', 'big-grid') }
									: { ...bem('text-container-inner') })}
							>
								<h2 className='h1'>{data.title}</h2>
								<p>{data.text}</p>

								<div {...bem('links')}>
									{data.linkAppStore &&
										data.imageAppStore &&
										data.imageAppStoreAlt && (
											<Link to={data.linkAppStore}>
												<img
													src={`${cdnHost}${data.imageAppStore}`}
													alt={data.imageAppStoreAlt}
												/>
											</Link>
										)}
									{data.linkGooglePlay &&
										data.imageGooglePlay &&
										data.imageGooglePlayAlt && (
											<Link to={data.linkGooglePlay}>
												<img
													src={`${cdnHost}${data.imageGooglePlay}`}
													alt={data.imageGooglePlayAlt}
												/>
											</Link>
										)}
								</div>
							</div>
						</div>
					</div>
				</Container>
			</div>
		</BrickContainer>
	)
}

export default NewAppBrick
