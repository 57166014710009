import React from 'react'
import useSetting from '../hooks/useSetting'
import {
	breakpointXs,
	breakpointSm,
	breakpointMd,
	breakpointLg,
	breakpointXl,
} from '../styles/1__settings/_vars.module.scss'

const Picture = ({
	alt,
	xsmall,
	small,
	medium,
	large,
	xlarge,
	defaultImage,
}) => {
	const breakpoints = {
		xsmall: `(max-width: ${breakpointXs})`,
		small: `(max-width: ${breakpointSm})`,
		medium: `(max-width: ${breakpointMd})`,
		large: `(max-width: ${breakpointLg})`,
		xlarge: `(max-width: ${breakpointXl})`,
	}
	const getSetting = useSetting()
	const cdnHost = getSetting('CDNHost', '')

	return (
		<picture>
			{xsmall && (
				<source srcSet={`${cdnHost}${xsmall}`} media={breakpoints.xsmall} />
			)}
			{small && (
				<source srcSet={`${cdnHost}${small}`} media={breakpoints.small} />
			)}
			{medium && (
				<source srcSet={`${cdnHost}${medium}`} media={breakpoints.medium} />
			)}
			{large && (
				<source srcSet={`${cdnHost}${large}`} media={breakpoints.large} />
			)}
			{xlarge && (
				<source srcSet={`${cdnHost}${xlarge}`} media={breakpoints.xlarge} />
			)}

			{defaultImage && <img src={`${cdnHost}${defaultImage}`} alt={alt} />}
		</picture>
	)
}

export default Picture
