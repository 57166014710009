import './LeadBrick.scss'
import '../CustomButtons.scss'
import React, { useState, useEffect } from 'react'
import BEMHelper from 'react-bem-helper'
import { get } from 'lodash'
import Container from '@material-ui/core/Container'
import { Formik, Form } from 'formik'
import { useConnect } from 'redux-bundler-hook'
import { graphql, useStaticQuery } from 'gatsby'
import Button from '@material-ui/core/Button'
import BrickContainer from '@src/components/bricks/BrickContainer'
import { TextField } from '@material-ui/core/'
import useTranslations from '@src/hooks/useTranslations'
import validator from '@src/form/validations'
import Consent from '@src/components/Consent'
import { smallDesktopSize } from '@src/javascript/variables'
import { azureApiGatewayPost } from '@src/api/azureApiGateway/client'
import ErrorModal from '@src/components/ErrorModal'

const bem = new BEMHelper('lead-brick')

const query = graphql`
	query getSnippets {
		allSnippet {
			edges {
				node {
					editables {
						name
						type
						value
					}
					name
				}
			}
		}
	}
`

export default function LeadBrick({ ...data }) {
	const t = useTranslations()

	const [errorMessage, showErrorMessage] = useState(null)

	const [isDesktop, setIsDesktop] = useState(true)
	useEffect(() => {
		const handleResize = () => {
			setIsDesktop(
				typeof window === 'undefined' || window.innerWidth > smallDesktopSize,
			)
		}
		window.addEventListener('resize', handleResize)
		setIsDesktop(
			typeof window === 'undefined' || window.innerWidth > smallDesktopSize,
		)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const { customerInfo } = useConnect('selectCustomerInfo')
	const { allSnippet } = useStaticQuery(query)
	const privacyPlocySnippet = allSnippet.edges.find(
		({ node }) => node.name === 'privacyPolicy',
	)
	function getSnippetValue(valueName) {
		const field = privacyPlocySnippet.node.editables.find(
			(editable) => editable.name === valueName,
		)
		return field && field.value
	}

	return (
		<BrickContainer data={data}>
			<div
				{...(data.bigGrid && isDesktop
					? { ...bem('', 'big-grid') }
					: { ...bem('') })}
				id={'lead-brick'}
			>
				<Container maxWidth={data.bigGrid && 'md'}>
					<div {...bem('title')}>
						{data.titleTag === 'H2' ? (
							<h2 className='h3'>{data.title}</h2>
						) : data.titleTag === 'H3' ? (
							<h3 className='h3'>{data.title}</h3>
						) : (
							data.titleTag === 'H4' && <h4 className='h3'>{data.title}</h4>
						)}
					</div>

					<Formik
						// todo: wait until setCustomer is done
						initialValues={{
							email:
								customerInfo && customerInfo.email ? customerInfo.email : '',
						}}
						validationSchema={validator({
							email: {
								validatorKey: 'email',
								required: true,
							},
						})}
						enableReinitialize={true}
					>
						{(props) => {
							const {
								values,
								isValid,
								handleChange,
								handleBlur,
								errors,
								touched,
							} = props
							const disabled = !isValid || values.email === ''

							return (
								<Form>
									<h3>{data.formHeading}</h3>
									<div {...bem('email-row')}>
										<TextField
											value={values.email}
											label={t('kinto.form.email')}
											variant='outlined'
											name='email'
											required
											onChange={handleChange}
											onBlur={handleBlur}
											error={errors.email && touched.email}
											helperText={errors.email && touched.email && errors.email}
										/>
										<Button
											className='ButtonOrange'
											aria-label={data.buttonText}
											variant='contained'
											color='primary'
											disabled={disabled}
											onClick={async () => {
												try {
													const response = await azureApiGatewayPost(
														'crm/lead',
														{
															email: values.email,
															page: window.location.pathname,
															type: data.area,
														},
													)
													const leadId = response.data.lead_id
													window.location.href = `${data.formUrl}?id=${leadId}&email=${values.email}`
												} catch (error) {
													if (get(error, 'response.data.messages')) {
														showErrorMessage(error.response.data.messages)
													}
												}
											}}
										>
											{data.buttonText}
										</Button>
									</div>
									<p>
										{data.privacyText}{' '}
										<Consent
											header={getSnippetValue('header')}
											linkText={getSnippetValue('linkText')}
											content={getSnippetValue('content')}
										></Consent>
									</p>
								</Form>
							)
						}}
					</Formik>

					{errorMessage && (
						<ErrorModal onClose={() => showErrorMessage(null)}>
							{errorMessage}
						</ErrorModal>
					)}
				</Container>
			</div>
		</BrickContainer>
	)
}
